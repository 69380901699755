import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RoomTable } from "types/enums";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import { Theme } from "@mui/material/styles";
import { isNil, isNotNil } from "helpers/utils";
import MDAvatar from "components/MDAvatar";
import HotelIcon from "@mui/icons-material/Hotel";
import KingBedIcon from "@mui/icons-material/KingBed";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AirlineSeatFlatAngledIcon from "@mui/icons-material/AirlineSeatFlatAngled";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";

import HomeIcon from "@mui/icons-material/Home";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import io from "socket.io-client";
import { Andar, Room, RoomInfo } from "types/websocket-schema";
import { ReactNode, memo, useEffect, useState } from "react";
import { use } from "i18next";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import defaultAvatar from "assets/images/defaultAvatar.svg";
const category = [
    RoomTable.RESIDENT,
    RoomTable.ROOM,
    RoomTable.HRRR,
    RoomTable.STATUS,
    RoomTable.BLOODPRESSURE,
    RoomTable.BLOODSUGAR,
];

interface Props {
    data: { [key: string]: Room };
    filter?: string;
}
interface RealTimeInfoTableProps {
    filter?: string;
    data?: { [key: string]: Room };
}

export default function RealTimeInfoTable(props: RealTimeInfoTableProps) {
    const { filter, data } = props;
    const { t: translate } = useTranslation(["common"]);
    if (!data) {
        return (
            <MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                    }}>
                    <MDTypography variant="h5">{translate("info.realTimeInfo.noData")}</MDTypography>
                </MDBox>
            </MDBox>
        );
    }
    const getRoomStatus = (
        kitchen: RoomInfo,
        bedRoom: RoomInfo,
        livingRoom: RoomInfo,
        bathRoom: RoomInfo,
        ander: Andar,
    ) => {
        const rooms = [
            { room: kitchen, name: "kitchen" },
            { room: bedRoom, name: "bedRoom" },
            { room: livingRoom, name: "livingRoom" },
            { room: bathRoom, name: "bathRoom" },
        ];

        for (let { room, name } of rooms) {
            if (room?.people) {
                return (
                    <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                        <HomeIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                        {translate(`info.realTimeInfo.${name}`)}
                    </MDBox>
                );
            }
        }
        if (ander?.people) {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <HomeIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    {translate(`info.realTimeInfo.bedRoom`)}
                </MDBox>
            );
        }
        //if all room is null
        if (!kitchen && !bedRoom && !livingRoom && !bathRoom) {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <MeetingRoomIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    N/A
                </MDBox>
            );
        }
        return (
            <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <MeetingRoomIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                {translate(`info.realTimeInfo.notInRoom`)}
            </MDBox>
        );
    };

    const getResidentStatus = (
        kitchen: RoomInfo,
        bedRoom: RoomInfo,
        livingRoom: RoomInfo,
        bathRoom: RoomInfo,
        hasLiveData: boolean,
    ) => {
        const rooms = [
            { room: kitchen, name: "kitchen" },
            { room: bedRoom, name: "bedRoom" },
            { room: livingRoom, name: "livingRoom" },
            { room: bathRoom, name: "bathRoom" },
        ];
        const content = (level: number, text: any) => {
            return (
                <MDTypography
                    variant="subtitle2"
                    fontSize="16px"
                    padding="0px 4px"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "4px 6px",
                        backgroundColor: level === 1 ? "#AD1818" : level === 2 ? "#3B5AFF" : "#56CE0C",
                        color: "white ",
                        borderRadius: "5px",
                    }}>
                    {text}
                </MDTypography>
            );
        };
        if (!hasLiveData) {
            return (
                <MDTypography
                    variant="subtitle2"
                    fontSize="16px"
                    padding="0px 4px"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "4px 6px",
                        backgroundColor: "#FF6B6B",
                        color: "white ",
                        borderRadius: "5px",
                    }}>
                    {translate(`info.realTimeInfo.offline`)}
                </MDTypography>
            );
        }
        for (let { room, name } of rooms) {
            if (room?.fallDown) {
                return content(1, translate(`info.realTimeInfo.fall`));
            } else if (room?.toLong) {
                return content(1, translate(`info.realTimeInfo.toLong`));
            } else {
                return content(3, translate(`info.realTimeInfo.safe`));
            }
        }
    };

    const isFallAndLong = (bed: boolean, livingRoom: boolean, kitchen: boolean, bathRoom: boolean) => {
        if (bed || livingRoom || kitchen || bathRoom) {
            return true;
        }
        return false;
    };

    const getVsRoomStatus = (status: number) => {
        if (status == 0) {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <MeetingRoomIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    {/* {translate(`info.realTimeInfo.notInRoom`)} */}
                    N/A
                </MDBox>
            );
        }
        if (status == 1 || status == 2 || status == 4) {
            return (
                <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <HomeIcon fontSize="inherit" sx={{ color: "#F4B8F5" }} />
                    {translate(`info.realTimeInfo.bedRoom`)}
                </MDBox>
            );
        }
    };

    const CustomTableRow = memo(function CustomTableRow({ data, filter }: Props) {
        const navigate = useNavigate();
        const handleClick = (residentId: string, destination: string) => {
            window.history.pushState({ prevUrl: "/dashboard" }, null);
            switch (destination) {
                case "resident":
                    navigate("/overview/profile", { state: { residentId: residentId } });
                    break;
                case "unit":
                    navigate("/overview/overview-unit", { state: { roomId: residentId } });
                    break;
                case "physiological":
                    navigate("/daily-measurement-and-survey/physiological-data", {
                        state: { residentId: residentId, tag: "chart" },
                    });
                    break;
                default:
                    break;
            }
        };
        return (
            <>
                {Object.keys(data)
                    .filter((key) => !filter || data[key].roomName.includes(filter) || data[key].name.includes(filter))
                    .map((key) => {
                        let isWarning = 0;
                        if (
                            isFallAndLong(
                                data[key]?.bedRoom?.fallDown,
                                data[key]?.kitchen?.fallDown,
                                data[key]?.livingRoom?.fallDown,
                                data[key]?.bathRoom?.fallDown,
                            )
                        ) {
                            isWarning = 1;
                        }
                        if (data[key].andar?.alarm) {
                            isWarning = 2;
                        }
                        if (data[key].hasLiveData === false) {
                            return;
                        }
                        return (
                            <TableRow
                                hover
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "#E9DEEE !important",
                                    },
                                }}
                                key={`TableRow_${key}`}>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    onClick={() => handleClick(key, "resident")}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                        width: "25%",
                                    })}>
                                    <MDTypography
                                        variant="subtitle2"
                                        textAlign={"center"}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            float: "left",
                                        }}>
                                        <MDBox
                                            sx={{
                                                height: "10px",
                                                width: "10px",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                    isWarning === 0
                                                        ? " transparent"
                                                        : isWarning === 1
                                                        ? " red"
                                                        : " yellow",
                                                display: "inline-block",
                                            }}
                                        />
                                        <MDAvatar
                                            sx={{
                                                margin: "0px 16px",
                                            }}
                                            src={data[key]?.avatar? data[key].avatar : defaultAvatar}
                                        />
                                        <MDBox
                                            sx={{ display: "flex", flexDirection: "column" }}
                                            alignItems={"flex-start"}>
                                            <MDBox>{data[key].roomName}</MDBox>
                                            <MDTypography
                                                variant="subtitle2"
                                                textAlign={"center"}
                                                sx={{ textWrap: "nowrap", paddingTop: "4px" }}>
                                                {data[key].name}
                                            </MDTypography>
                                        </MDBox>
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    onClick={() => handleClick(key, "unit")}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                        width: "13%",
                                    })}>
                                    <MDTypography variant="subtitle2" textAlign={"center"} fontSize="16px">
                                        {getRoomStatus(
                                            data[key].kitchen,
                                            data[key].bedRoom,
                                            data[key].livingRoom,
                                            data[key].bathRoom,
                                            data[key].andar,
                                        )}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    onClick={() => handleClick(key, "unit")}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography
                                        variant="subtitle2"
                                        textAlign={"center"}
                                        sx={{ color: isWarning == 2 ? "red" : "" }}>
                                        {`${data[key].andar?.bpm ? data[key].andar?.bpm : "--"}/ ${
                                            data[key].andar?.rpm ? data[key].andar?.rpm : "--"
                                        }`}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography
                                        variant="subtitle2"
                                        // textAlign={"center"}
                                        fontSize="16px"
                                        padding="0px 4px"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: "4px",
                                        }}>
                                        {getResidentStatus(
                                            data[key].kitchen,
                                            data[key].bedRoom,
                                            data[key].livingRoom,
                                            data[key].bathRoom,
                                            data[key].hasLiveData,
                                        )}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    onClick={() => handleClick(key, "physiological")}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography variant="subtitle2" textAlign={"center"}>
                                        {`${
                                            data[key].bloodPressure?.systolic ? data[key].bloodPressure?.systolic : "--"
                                        }/ 
                                        ${
                                            data[key].bloodPressure?.diastolic
                                                ? data[key].bloodPressure?.diastolic
                                                : "--"
                                        }`}
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    p={1.5}
                                    onClick={() => handleClick(key, "physiological")}
                                    sx={({
                                        palette: { light },
                                        typography: { size },
                                        borders: { borderWidth },
                                    }: Theme) => ({
                                        fontSize: size.sm,
                                    })}>
                                    <MDTypography variant="subtitle2" textAlign={"center"}>
                                        {data[key].bloodSugar ? data[key].bloodSugar : "--"}
                                    </MDTypography>
                                </MDBox>
                            </TableRow>
                        );
                    })}
            </>
        );
    });
    return (
        <TableContainer sx={{ backgroundColor: "white !important", borderRadius: 3 }}>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
                <MDBox component="thead">
                    {category.map((item, i) => (
                        <TableCell
                            key={i}
                            align={item != RoomTable.RESIDENT && item != RoomTable.ROOM ? "center" : "left"}>
                            <MDTypography
                                variant="subtitle2"
                                key={i}
                                sx={{
                                    display: "inline-block",
                                    fontWeight: "medium",
                                    mx: item !== RoomTable.ROOM ? 2 : 0,
                                    paddingTop: "20px",
                                }}>
                                {item ? translate(`info.realTimeInfo.${item}`) : ""}
                            </MDTypography>
                        </TableCell>
                    ))}
                </MDBox>

                <TableBody>
                    <CustomTableRow data={data} filter={filter} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}
