import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ActiveProps } from "types/state";
import { isNotNil } from "helpers/utils";
import MDTypography from "components/MDTypography";
import { tabContainer, tabContent } from "components/MDTab/tab.styles";

interface Props extends ActiveProps {
    list: { key: string; value: string; disabled?: boolean }[];
    scrollable?: boolean;
    isIcon?: boolean;
    noPadding?: boolean;
    setFallback?(id: string): void;
}

export const MDTab: React.FC<Props> = ({ list, active, scrollable, setActive, isIcon, noPadding, setFallback }) => {
    const handleChange = (event: React.SyntheticEvent, newVal: string) => {
        setActive(newVal);
        if (setFallback) {
            setFallback(newVal);
        }
    };
    return (
        <MDBox px={noPadding ? 0 : 2} sx={{ maxWidth: 700 }}>
            {isNotNil(active) && (
                <Tabs
                    value={active}
                    onChange={handleChange}
                    variant={scrollable ? "scrollable" : "fullWidth"}
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#303E4A",
                        },
                    }}
                    aria-label="scrollable auto tabs">
                    {isIcon
                        ? list.map((item, idx) => (
                              <Tab
                                  sx={() => tabContainer({ isIcon })}
                                  key={idx}
                                  label={
                                      <Icon fontSize="medium" sx={(theme: any) => tabContent(theme, { active, item })}>
                                          {item.key}
                                      </Icon>
                                  }
                                  value={item.value}
                                  disabled={item.disabled}
                              />
                          ))
                        : list.map((item, idx: number) => (
                              <Tab
                                  sx={() => tabContainer({ isIcon })}
                                  key={idx}
                                  label={
                                      <MDTypography
                                          variant="subtitle2"
                                          sx={(theme: any) => tabContent(theme, { active, item })}>
                                          {item.key}
                                      </MDTypography>
                                  }
                                  value={item.value}
                                  disabled={item.disabled}
                              />
                          ))}
                </Tabs>
            )}
        </MDBox>
    );
};

MDTab.defaultProps = {
    scrollable: true,
    isIcon: false,
};

export default MDTab;
