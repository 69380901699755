import React, { useState, useEffect, useCallback } from "react";

import MDBox from "components/MDBox";
import MDCircularProgress from "components/MDCircularProgress";
import MDNotification from "components/MDNotification";
import MDTab from "components/MDTab";
import AddButton from "components/MDButton/addButton";

import BasicLayout from "examples/LayoutContainers/BasicLayout";
import NewAccount from "layouts/account/newAccount";
import ViewAccount from "layouts/account/viewAccount";
import AccountTable from "./components/AccountTable";

// Services
import BusinessService from "services/business.service";
import UsersService from "services/user.service";

// Types
import { Business } from "types/setting-schema";
import { User } from "types/user-schema";
import { FormOperation } from "types/enums";

// Helpers
import { isNotNil, isNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";

//test
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page">
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(name: string, calories: number, fat: number) {
    return { name, calories, fat };
}

export const Account: React.FC = () => {
    const { t: translate } = useTranslation(["common"]);
    const [businessData, setBusinessData] = useState<Business[] | null>(null);
    const [users, setUsers] = useState<User[] | null>(null);
    const [activeBusiness, setActiveBusiness] = useState<string | null>(null);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [mode, setMode] = useState<{ op: FormOperation; accountId: string } | null>(null);
    const [formAlert, setFormAlert] = useState<boolean>(false);




    useEffect(() => {
        const fetchBusinesses = async () => {
            const res = await BusinessService.getAllBusinesses();
            if (res.success) {
                setBusinessData(res.data);
                setActiveBusiness(res.data[0]._id);
            }
        };
        fetchBusinesses();
    }, []);

    useEffect(() => {
        setUsers(null);

        if (isNotNil(businessData) && isNotNil(activeBusiness)) {
            fetchAccounts();
        }
    }, [businessData, activeBusiness]);

    useEffect(() => {
        if (formAlert) {
            setTimeout(() => {
                setFormAlert(false);
            }, 3000);
        }
    }, [formAlert]);

    const fetchAccounts = async () => {
        const res = await UsersService.getAllUsersByBusiness(activeBusiness);
        if (res.success) {
            setUsers(res.data);
        }
    };

    const handleToggleModal = useCallback((accountId?: string, op: FormOperation = FormOperation.CREATE) => {
        setMode({ op, accountId });
        setToggleModal(true);
    }, []); // Add any dependencies here

    const childCallback = async (updated: boolean, isDelete?: boolean) => {
        if (updated) {
            if (isDelete) setMode({ ...mode, op: FormOperation.DELETE });
            fetchAccounts();
            setToggleModal(false);
            setFormAlert(true);
        }
    };

    const handleReactivate = async (id: string) => {
        const res = await UsersService.reactivate(id, translate("lang"));
        if (res.success) {
            setMode(null);
            setFormAlert(true);
        }
    };

    const renderPage = () => {
        return isNil(businessData) || isNil(activeBusiness) ? (
            <MDCircularProgress />
        ) : (
            <MDBox>
                <MDBox mb={3}>
                    <MDTab
                        list={businessData.map((item) => ({ key: item.name, value: item._id }))}
                        active={activeBusiness}
                        setActive={setActiveBusiness}
                    />
                </MDBox>
                <MDBox mb={2} px={2}>
                    <AddButton
                        content={translate("general.button.addAccount")}
                        handleToggleModal={handleToggleModal}
                        isDisabled={businessData?.find((item) => item._id === activeBusiness)?.numCenters === 0}
                    />
                </MDBox>
                {isNil(users) ? (
                    <MDCircularProgress />
                ) : (
                    <AccountTable list={users} handleOnClick={handleToggleModal} handleReactivate={handleReactivate} />
                )}
            </MDBox>
        );
    };

    const renderForm = () => {
        if (mode.op === FormOperation.UPDATE) {
            const at = users.find((user) => user._id === mode.accountId);
            return (
                <ViewAccount
                    setToggleModal={setToggleModal}
                    businessId={activeBusiness}
                    account={at}
                    callback={childCallback}
                />
            );
        } else {
            return <NewAccount setToggleModal={setToggleModal} businessId={activeBusiness} callback={childCallback} />;
        }
    };

    const renderNotification = () => {
        if (isNil(mode)) {
            return <MDNotification content={translate("general.notification.invitation")} />;
        } else {
            return (
                <MDNotification
                    content={translate("general.notification.message", {
                        type: `${translate("general.type.account")}`,
                        operation: `${translate(`general.operation.past.${mode.op}`)}`,
                    })}
                />
            );
        }
    };

    return (
        <BasicLayout>
            {toggleModal && renderForm()}
            {formAlert && renderNotification()}
            {renderPage()}
        </BasicLayout>
    );
};
Account.whyDidYouRender = true;
export default Account;
