/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDCircularProgressRoot from "components/MDCircularProgress/MDCircularProgressRoot";
// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import WebsocketService from "services/websocket.service";
import AuthService from "services/auth.service";
import { Room, WebSocketProps } from "types/websocket-schema";

import { UnitStatus } from "./components/UnitStatus";
import { convertToObject, isFallAndLong, isNotNil, updateData } from "helpers/utils";
import io from "socket.io-client";
import { useAuth } from "context/userContext";
import { useNavigate } from "react-router-dom";
import { Roles } from "types/roles";
import AlarmService from "services/alarm.service";
import RealTimeInfoTable from "examples/Tables/RealTimeInfoTable";
import { useTranslation } from "react-i18next";
import WistronHome from "layouts/business/wistron-home";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { debounce } from "lodash";
import Footer from "examples/Footer";
import deviceService from "services/device.service";

const renderInfoCard = (title: string, children?: ReactNode, background?: boolean, onClick?: () => void) => {
    return (
        <MDBox
            onClick={onClick}
            sx={{
                height: "130px",
                backgroundColor: "#FFFFFF",
                borderRadius: "13px",
                boxShadow: "0px 2px 6px 0px #00000026",
                padding: "30px",
                position: "relative",
                overflow: "hidden",
                cursor: onClick ? "pointer" : "normal",
            }}>
            {background && (
                <img
                    src={require("assets/images/dashboard/user_block_bg.png")}
                    alt="active-alert"
                    style={{
                        position: "absolute",
                        width: "20vw",
                        maxWidth: "256px",
                        top: "clamp(-134px, 10vw, -10vw)",
                        right: "-50px",
                        zIndex: 0,
                    }}
                />
            )}
            <MDBox
                sx={{
                    fontSize: "16px",
                    fontWeight: "300",
                    color: "#7B809A",
                    lineHeight: "22.4px",
                    zIndex: 2,
                    position: "relative",
                }}>
                {title}
            </MDBox>
            {children}
        </MDBox>
    );
};

export const Dashboard: React.FC = () => {
    const [disconnectData, setDisconnectData] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [deviceStatus,setDeviceStatus] = useState<{online:number,disconnected:number}>({online:0,disconnected:0});
    const [tableData, setTableData] = useState<{ [key: string]: Room }>(null);
    const [cardData, setCardData] = useState<any>(null);
    const { currentUser, setCurrentUser } = useAuth();
    const [alertData, setAlertData] = useState<{ todayAlerts: number; yesterdayAlerts: number }>(null);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        if (!isNotNil(currentUser)) return;
        const socket = io(
            process.env.REACT_APP_ENV === "production"
                ? "https://api.realeasyliving.com/"
                : process.env.REACT_APP_ENV === "development"
                ? "https://devapi.realeasyliving.com/"
                : "http://localhost:6000/",
            // process.env.REACT_APP_ENV === "production" ? "https://api.realeasyliving.com/" : "http://localhost:6000/",
            {
                //transports : ['websocket'],
                query: {
                    type_id: `dashboard-${currentUser?.business}`,
                },
            },
        );

        socket.on("connect", async () => {
            const socketId = socket.id;
        });
        socket.on("data", (channel, message) => {
            let tableData = convertToObject(channel.data.dashboard);
            let cardData = {
                activeDevice: channel.data.activeDevice,
                totalDevice: channel.data.totalDevice,
                user: Object.keys(tableData).length,
            };
            setTableData((prevData) => updateData(prevData, tableData));
            setCardData(cardData);
            // setTableData(tableData);
        });
        let fetchInterval: NodeJS.Timeout | undefined;
        switch (currentUser?.role) {
            case Roles.relAdmin:
                fetchInterval = setInterval(() => fetchAlertHistory("businessId", currentUser.business), 5000);
                break;
            case Roles.businessAdmin:
                fetchInterval = setInterval(() => fetchAlertHistory("businessId", currentUser.business), 5000);
                break;
            case Roles.centerAdmin:
            case Roles.centerStaff:
                fetchInterval = setInterval(() => fetchAlertHistory("centerId", currentUser.center.id), 5000);
                break;
            default:
                break;
        }
        return () => {
            socket.disconnect();
            if (fetchInterval) clearInterval(fetchInterval);
        };
    }, [currentUser]);
    const handleAlertHistory = () => {
        window.history.pushState({ prevUrl: "/dashboard" }, null);
        if (currentUser.role === Roles.relAdmin)
            navigate("/dashboard/alert-history", { state: { businessId: currentUser.business } });
        else if (currentUser.role === Roles.centerAdmin || currentUser.role === Roles.centerStaff)
            navigate("/dashboard/alert-history", { state: { centerId: currentUser.center.id } });
        else if (currentUser.role === Roles.businessAdmin)
            navigate("/dashboard/alert-history", { state: { businessId: currentUser.business } });
        else navigate("/dashboard/alert-history");
    };
    const handleResident = () => {
        window.history.pushState({ prevUrl: "/dashboard" }, null);
        navigate("/setting/resident", { state: { businessId: currentUser.business } });
    };

    const handleDevice = () => {
        window.history.pushState({ prevUrl: "/dashboard" }, null);
        navigate("/device", {});
    };
    const fetchAlertHistory = async (type: "businessId" | "centerId" | "all", value: string) => {
        const res = await AlarmService.getTodayBusinessAlarm(
            type,
            value,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
        if (res.success) {
            setAlertData({
                todayAlerts: res.data.todayAlerts.length,
                yesterdayAlerts: res.data.totalAlertsYesterday,
            });
        }
    };
    const calculatePercentageChange = (yesterdayAlert: number, todayAlert: number): JSX.Element => {
        let result = Math.round(((todayAlert - yesterdayAlert) / yesterdayAlert) * 100);
        if (yesterdayAlert === 0) result = 100;
        if (todayAlert === 0) result = -100;

        return (
            <>
                <MDTypography sx={{ color: result <= 0 ? "#0FB059" : "#CA1818" }}>
                    {result >= 100 ? `+${result}` : result}%
                </MDTypography>
                {translate(`info.realTimeInfo.sinceYesterday`)}
            </>
        );
    };

    const fetchDisconnectDeviceData = async () => {
        // Fetch device data from API
        const res = await deviceService.getDisconnectDeviceData();
        if (res.success) {
            console.log(res.data);
            setDisconnectData(res.data);
        }
    };
    const fetchDeviceData = async () => {
        // Fetch device data from API
        const res = await deviceService.getDeviceData();
        if (res.success) {
            console.log(res.data);
            setData(res.data);
        }
    };

    useEffect(() => {
        fetchDeviceData();
        fetchDisconnectDeviceData();
    }, []);

    //handle the data, when both data and disconnectData are ready
    useEffect(() => {
        if (data && disconnectData) {
            const specialDeviceIds = [
                "d2bd70f0-55ef-11ef-85f9-5b765dec8351", 
                "df6fc7b0-4b01-11ef-85f9-5b765dec8351", 
                "222039e0-536e-11ef-85f9-5b765dec8351", 
                "8ecad500-fcf4-11ec-b9c7-c9051ffe80db", 
                "a41c2b30-2d39-11ef-bbec-ab837134bae0", 
                "0caec4b0-2cf3-11ef-bbec-ab837134bae0", 
                "203a0370-fcf6-11ec-b9c7-c9051ffe80db", 
                "0fc99f20-f07e-11ec-8999-053df5604be9", 
                "7a29b750-559f-11ef-85f9-5b765dec8351", 
                "9c3f01b0-2d3b-11ef-bbec-ab837134bae0",
                "da285060-2506-11ed-8074-bb7d7ad82e98"
            ];
    
            let onlineCount = 0;
            let offlineCount = 0;
    
            const newData: any[] = data.map((device: any) => {
                const deviceList: any[] = [];
                if (device.deviceXinfo.length > 0) {
                    device.deviceXinfo.map((deviceX: any) => {
                        const deviceData = disconnectData.find((d: any) => d.sn === deviceX.deviceid);
                        const isSpecialDevice = specialDeviceIds.includes(device.id);
                        if (deviceData) {
                            const timestamp = deviceData.dt * 1000;
                            const currentTimeInMillis = Date.now();
                            const isDisconnected = isSpecialDevice ? false : currentTimeInMillis - timestamp > 60000;
                            if (!isDisconnected) {
                                onlineCount++;
                            } else {
                                offlineCount++;
                            }
                            deviceList.push({
                                ...deviceX,
                                type: "anderX",
                                updateData: deviceData.dt,
                                disconnected: isDisconnected,
                            });
                        } else {
                            const isDisconnected = isSpecialDevice ? false : true;
                            if (!isDisconnected) {
                                onlineCount++;
                            } else {
                                offlineCount++;
                            }
                            deviceList.push({
                                ...deviceX,
                                type: "anderX",
                                updateData: null,
                                disconnected: isDisconnected,
                            });
                        }
                    });
                }

                const deviceData = disconnectData.find((d: any) => device.deviceid === d.sn);
                const isSpecialDevice = specialDeviceIds.includes(device.id);
                console.log(isSpecialDevice, device.deviceid);
                if (deviceData) {
                    const timestamp = deviceData.dt * 1000;
                    const currentTimeInMillis = Date.now();
                    const isDisconnected = isSpecialDevice ? false : currentTimeInMillis - timestamp > 60000;

                    if (!isDisconnected) {
                        onlineCount++;
                    } else {
                        offlineCount++;
                    }
                    deviceList.push({
                        deviceid: device.deviceid,
                        type: "ander",
                        updateDate: deviceData.dt,
                        disconnected: isDisconnected,
                    });
                } else {
                    const isDisconnected = isSpecialDevice ? false : true;
                    if (!isDisconnected) {
                        onlineCount++;
                    } else {
                        offlineCount++;
                    }
                    deviceList.push({
                        deviceid: device.deviceid,
                        type: "ander",
                        updateDate: null,
                        disconnected: isSpecialDevice ? false : true,
                    });
                }

                return {
                    ...device,
                    disconnectData: deviceList,
                };
            });
            setDeviceStatus({online:onlineCount,disconnected:offlineCount});
            console.log('Online Devices:', onlineCount);
            console.log('Offline Devices:', offlineCount);
        }
    }, [data, disconnectData]);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={12}>
                    <MDTypography variant="h2" mb={3} mt={3}>
                        {translate(`dashboard.activity`)}
                    </MDTypography>
                </Grid>
                <Grid item xs={12} md={4}>
                    {renderInfoCard(
                        translate(`info.realTimeInfo.activeAlert`),
                        <MDBox
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                placeContent: alertData === null ? "center" : "",
                            }}>
                            {alertData === null ? (
                                <CircularProgress size={50} />
                            ) : (
                                <>
                                    <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                        {alertData?.todayAlerts ? alertData.todayAlerts : 0}
                                    </MDTypography>
                                    <MDTypography
                                        sx={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            marginTop: "auto",
                                            fontSize: "16px",
                                            lineHeight: "22.4px",
                                            color: "#344767",
                                        }}>
                                        {calculatePercentageChange(alertData?.yesterdayAlerts, alertData?.todayAlerts)}
                                    </MDTypography>
                                </>
                            )}
                        </MDBox>,
                        false,
                        handleAlertHistory,
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    {renderInfoCard(
                        translate(`info.realTimeInfo.allUser`),
                        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                            <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                {cardData?.user}
                            </MDTypography>
                        </MDBox>,
                        true,
                        handleResident,
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    {renderInfoCard(
                        translate(`info.realTimeInfo.deviceStatus`),
                        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                            <MDBox sx={{ display: "flex", alignItems: "baseline", width: "100%" }}>
                                <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                    {deviceStatus.online}
                                </MDTypography>
                                <MDTypography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "22.4px",
                                        marginLeft: "18px",
                                        position: "relative",
                                        "&:before": {
                                            content: "''",
                                            width: "6px",
                                            height: "6px",
                                            background: "#0FB059",
                                            borderRadius: "25px",
                                            display: "inline-block",
                                            position: "absolute",
                                            left: "-9px",
                                            transform: "translateY(-50%);",
                                            top: "50%",
                                        },
                                    }}>
                                    On
                                </MDTypography>
                            </MDBox>
                            <MDBox sx={{ display: "flex", alignItems: "baseline", width: "100%" }}>
                                <MDTypography sx={{ fontSize: "32px", lineHeight: "44.8px" }}>
                                    {deviceStatus.disconnected}
                                </MDTypography>
                                <MDTypography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "22.4px",
                                        marginLeft: "18px",
                                        position: "relative",
                                        "&:before": {
                                            content: "''",
                                            width: "6px",
                                            height: "6px",
                                            background: "#CD1111",
                                            borderRadius: "25px",
                                            display: "inline-block",
                                            position: "absolute",
                                            left: "-9px",
                                            transform: "translateY(-50%);",
                                            top: "50%",
                                        },
                                    }}>
                                    Off
                                </MDTypography>
                            </MDBox>
                        </MDBox>,
                        false,
                        handleDevice,
                    )}
                </Grid>
                <Grid item xs={12}>
                    <MDTypography variant="h2" mb={3} mt={3}>
                        {translate(`dashboard.residentStatus`)}
                    </MDTypography>
                    <RealTimeInfoTable data={tableData} />
                </Grid>
            </Grid>
            <Footer />
        </DashboardLayout>
    );
};

export default Dashboard;
