/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Unused imports
import Dashboard from "layouts/dashboard";
// import ProfileOverview from "layouts/business/profile/profile-overview";
// import AllProjects from "layouts/business/profile/all-projects";
// import NewUser from "layouts/business/users/new-user";
// import Settings from "layouts/business/account/settings";
// import Timeline from "layouts/business/projects/timeline";
// import Widgets from "layouts/business/widgets";
// import Charts from "layouts/business/charts";

// import Notifications from "layouts/business/notifications";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";
// import NewProduct from "layouts/business/products/new-product";
// import EditProduct from "layouts/business/products/edit-product";
// import ProductPage from "layouts/business/products/product-page";
// import OrderList from "layouts/business/orders/order-list";
// import OrderDetails from "layouts/business/orders/order-details";

import Device from "layouts/device";
import WistronHome from "layouts/business/wistron-home";
import SignIn from "layouts/authentications/sign-in";
import Change from "layouts/authentications/change-password";
import Forgot from "layouts/authentications/forgot-password";
import BusinessSettingPage from "layouts/settings/business-page";
import Resident from "layouts/settings/resident-page";
import General from "layouts/settings/general-page";
import DisplaySingleBusiness from "layouts/settings/business-page/displaySingleBusiness";
import Account from "layouts/account";
import Info from "layouts/info";
import MeasurementPage from "layouts/info/measurement-page";
import ResidentInfo from "layouts/overview/resident-page";

import Icon from "@mui/material/Icon";
import HealthEducation from "layouts/health-education";
import HealthPrescription from "layouts/health-prescription";

import UnitOverView from "layouts/overview/unit-page";
import AlertHistoryPage from "layouts/dashboard/alert-history";
import BusinessInfoPage from "layouts/overview/business-page";
import CenterInfoPage from "layouts/overview/center-page";
import ReportPage from "layouts/report";
import RoomSettingPage from "layouts/settings/room-page";

export interface iRoute {
    type?: string;
    name?: string;
    key: string;
    remote?: boolean; // Remote controlled route,
    route?: string;
    icon?: JSX.Element;
    component?: JSX.Element;
    noCollapse?: boolean;
    collapse?: iRoute[];
    // Below properties are inserted in later stage
    title?: string;
    href?: string;
}

const routes: iRoute[] = [
    { type: "title", title: "General", key: "general-pages" },
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        remote: true,
        component: <Dashboard />,
        icon: <Icon fontSize="medium">dashboard</Icon>,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Report",
        key: "report",
        route: "/report",
        remote: true,
        component: <ReportPage />,
        icon: <Icon fontSize="medium">summarize</Icon>,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Device",
        key: "device",
        route: "/device",
        component: <Device />,
        icon: <Icon fontSize="medium">wifi</Icon>,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "overview",
        key: "overview",
        remote: false,
        icon: <Icon fontSize="medium">info</Icon>,
        collapse: [
            {
                name: "overview-business",
                key: "overview-business",
                route: "/overview/overview-business",
                remote: true,
                component: <BusinessInfoPage />,
            },
            {
                name: "Center",
                key: "overview-center",
                route: "/overview/overview-center",
                remote: true,
                component: <CenterInfoPage />,
            },
            {
                name: "Unit",
                key: "overview-unit",
                route: "/overview/overview-unit",
                remote: true,
                component: <UnitOverView />,
            },
            // {
            //     name: "Resident",
            //     key: "overview-resident",
            //     route: "/overview/resident",
            //     remote: true,
            //     component: <UnitOverView />,
            // },
        ],
    },
    {
        type: "collapse",
        name: "Settings",
        key: "settings",
        remote: true,
        icon: <Icon fontSize="medium">settings</Icon>,
        collapse: [
            {
                name: "Business",
                key: "business",
                route: "/setting/business",
                remote: true,
                component: <BusinessSettingPage />,
            },
            {
                name: "Resident",
                key: "resident",
                route: "/setting/resident",
                remote: true,
                component: <Resident />,
            },
            {
                name: "General",
                key: "general",
                route: "/setting/general",
                remote: true,
                component: <General />,
            },
            {
                name: "Room",
                key: "room",
                route: "/room",
                remote: true,
                component: <RoomSettingPage/>,
                icon: <Icon fontSize="medium">setting</Icon>,
            },
        ],
    },
    {
        type: "collapse",
        name: "Account",
        key: "account",
        route: "/account",
        remote: true,
        component: <Account />,
        icon: <Icon fontSize="medium">person_add</Icon>,
        noCollapse: true,
    },
    // {
    //     type: "collapse",
    //     name: "Profile",
    //     key: "profile",
    //     route: "/profile",
    //     remote: true,
    //     component: <Profile />,
    //     icon: <Icon fontSize="medium">person_add</Icon>,
    //     noCollapse: true,
    // },
    { type: "title", title: "Info", key: "info-pages" },
    {
        type: "collapse",
        name: "MeasurementAndSurvey",
        key: "daily-measurement-and-survey",
        route: "/daily-measurement-and-survey",
        component: <Info />,
        icon: <Icon fontSize="medium">assessment</Icon>,
        noCollapse: true,
    },
    // {
    //     type: "hide",
    //     name: "Business",
    //     key: "business",
    //     icon: <Icon fontSize="medium">person</Icon>,
    //     collapse: [
    //         {
    //             name: "Profile",
    //             key: "profile",
    //             collapse: [
    //                 {
    //                     name: "Profile Overview",
    //                     key: "profile-overview",
    //                     route: "/business/profile/profile-overview",
    //                     component: <ProfileOverview />,
    //                 },
    //                 {
    //                     name: "All Projects",
    //                     key: "all-projects",
    //                     route: "/business/profile/all-projects",
    //                     component: <AllProjects />,
    //                 },
    //             ],
    //         },
    //         {
    //             name: "Users",
    //             key: "users",
    //             collapse: [
    //                 {
    //                     name: "New User",
    //                     key: "new-user",
    //                     route: "/business/users/new-user",
    //                     component: <NewUser />,
    //                 },
    //             ],
    //         },
    //         {
    //             name: "Account",
    //             key: "account",
    //             collapse: [
    //                 {
    //                     name: "Settings",
    //                     key: "settings",
    //                     route: "/business/account/settings",
    //                     component: <Settings />,
    //                 },
    //             ],
    //         },
    //         {
    //             name: "Projects",
    //             key: "projects",
    //             collapse: [
    //                 {
    //                     name: "Timeline",
    //                     key: "timeline",
    //                     route: "/business/projects/timeline",
    //                     component: <Timeline />,
    //                 },
    //             ],
    //         },
    //         {
    //             name: "Widgets",
    //             key: "widgets",
    //             route: "/business/widgets",
    //             component: <Widgets />,
    //         },
    //         {
    //             name: "Charts",
    //             key: "charts",
    //             route: "/business/charts",
    //             component: <Charts />,
    //         },
    //         {
    //             name: "Notifications",
    //             key: "notifications",
    //             route: "/business/notifications",
    //             component: <Notifications />,
    //         },
    //         {
    //             name: "Products",
    //             key: "products",
    //             collapse: [
    //                 {
    //                     name: "New Product",
    //                     key: "new-product",
    //                     route: "/business/products/new-product",
    //                     component: <NewProduct />,
    //                 },
    //                 {
    //                     name: "Edit Product",
    //                     key: "edit-product",
    //                     route: "/business/products/edit-product",
    //                     component: <EditProduct />,
    //                 },
    //                 {
    //                     name: "Product Page",
    //                     key: "product-page",
    //                     route: "/business/products/product-page",
    //                     component: <ProductPage />,
    //                 },
    //             ],
    //         },
    //         {
    //             name: "Orders",
    //             key: "orders",
    //             collapse: [
    //                 {
    //                     name: "Order List",
    //                     key: "order-list",
    //                     route: "/business/orders/order-list",
    //                     component: <OrderList />,
    //                 },
    //                 {
    //                     name: "Order Details",
    //                     key: "order-details",
    //                     route: "/business/orders/order-details",
    //                     component: <OrderDetails />,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     type: "hide",
    //     name: "Applications",
    //     key: "applications",
    //     icon: <Icon fontSize="medium">apps</Icon>,
    //     collapse: [
    //         {
    //             name: "Data Tables",
    //             key: "data-tables",
    //             route: "/applications/data-tables",
    //             component: <DataTables />,
    //         },
    //         {
    //             name: "Calendar",
    //             key: "calendar",
    //             route: "/applications/calendar",
    //             component: <Calendar />,
    //         },
    //     ],
    // },
    {
        type: "hide",
        name: "Authentication",
        key: "authentication",
        collapse: [
            {
                name: "Sign In",
                key: "sign-in",
                route: "/sign-in",
                component: <SignIn />,
                noCollapse: true,
            },
            {
                name: "Forgot Password",
                key: "forgot-password",
                route: "/forgot-password",
                component: <Forgot />,
                noCollapse: true,
            },
            {
                name: "Change Password",
                key: "change-password",
                route: "/change-password",
                component: <Change />,
                noCollapse: true,
            },
            {
                name: "Activate Account",
                key: "activate",
                route: "/activate",
                component: <Change />,
                noCollapse: true,
            },
        ],
    },
    {
        type: "hide",
        name: "settings",
        key: "settings",
        collapse: [
            {
                name: "Display Single Business",
                key: "display single business",
                route: "/setting/business/:name",
                component: <DisplaySingleBusiness />,
            },
        ],
    },
    {
        type: "hide",
        name: "Dashboard",
        key: "dashboard",
        collapse: [
            {
                name: "Alert History",
                key: "alert-history",
                route: "/dashboard/alert-history",
                component: <AlertHistoryPage />,
            },
        ],
    },
    {
        type: "hide",
        name: "MeasurementAndSurvey",
        key: "daily-measurement-and-survey",
        collapse: [
            {
                name: "MeasurementAndSurvey",
                key: "daily-measurement-and-survey",
                route: "/daily-measurement-and-survey/physiological-data",
                component: <MeasurementPage />,
            },
        ],
    },
    {
        type: "hide",
        name: "HealthEducation",
        key: "education",
        collapse: [
            {
                name: "Health-Education",
                key: "education",
                route: "/education/",
                component: <HealthEducation />,
            },
        ],
    },
    {
        type: "hide",
        name: "HealthPrescription",
        key: "prescription",
        collapse: [
            {
                name: "Health-Prescription",
                key: "prescription",
                route: "/prescription/",
                component: <HealthPrescription />,
            },
        ],
    },
    {
        type: "hide",
        name: "overview",
        key: "overview",
        remote: true,
        icon: <Icon fontSize="medium">info</Icon>,
        collapse: [
            {
                name: "overview-profile",
                key: "overview-profile",
                route: "/overview/profile",
                component: <ResidentInfo />,
            },
        ],
    },
    {
        type: "hide",
        name: "report2",
        key: "report2",
        remote: true,
        icon: <Icon fontSize="medium">info</Icon>,
        collapse: [
            {
                name: "report2",
                key: "report2",
                route: "/report2",
                component: <RoomSettingPage />,
            },
        ],
    }
];

export default routes;
