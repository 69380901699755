/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/// <reference types="@welldone-software/why-did-you-render" />
import './wdyr';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "i18n";
import { StrictMode } from "react";

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <StrictMode>
        <BrowserRouter>
            <MaterialUIControllerProvider>
                <App />
            </MaterialUIControllerProvider>
        </BrowserRouter>
    </StrictMode>
);
