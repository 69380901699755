import { useState, useEffect, useCallback } from "react";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDCircularProgress from "components/MDCircularProgress";
import MDTab from "components/MDTab";
import MDBadge from "components/MDBadge";
import MDNotification from "components/MDNotification";
import MDNoData from "components/MDNoData";
import MDAvatar from "components/MDAvatar";
import MDFilter from "components/MDFilter";
import More from "components/MDIcon/more";
import AddButton from "components/MDButton/addButton";

// Components
import BasicLayout from "examples/LayoutContainers/BasicLayout";
import NewResident from "layouts/settings/resident-page/newResident";
import ViewResident from "layouts/settings/resident-page/viewResident";

// Business service
import BusinessService from "services/business.service";
import CenterService from "services/center.service";
import ResidentService from "services/resident.service";

// Types
import { Business, Center, Resident } from "types/setting-schema";
import { FormOperation } from "types/enums";

// Helpers
import { isNotNil, isNil, calculateAge } from "helpers/utils";
import { getBirthday } from "helpers/formUtil";
import defaultAvatar from "assets/images/defaultAvatar.svg";

// i18n
import { useTranslation } from "react-i18next";

import DataTable from "examples/Tables/DataTable";
import { useLocation } from "react-router-dom";

interface Cells {
    columns: { Header: string; accessor: string }[];
    rows: {
        avatar: JSX.Element;
        id: string;
        no: string;
        name: string;
        gender: string;
        age: string;
        center: string;
        type: JSX.Element | string;
        address: JSX.Element;
        more: JSX.Element;
    }[];
}

export const ResidentSettingPage: React.FC = () => {
    let state = useLocation().state as { businessId: string; centerId?: string } | null;
    const [businessData, setBusinessData] = useState<Business[]>(null);
    const [centerData, setCenterData] = useState<Center[]>(null);
    const [residentData, setResidentData] = useState<Resident[]>(null);
    const [activeBusiness, setActiveBusiness] = useState<string>(null);
    const [activeCenter, setActiveCenter] = useState<string>(null);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [formAlert, setFormAlert] = useState<boolean>(false);
    const [mode, setMode] = useState<{ op: FormOperation; residentId?: string } | null>(null);
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        fetchBusinesses();
    }, []);

    useEffect(() => {
        setCenterData(null);
        setResidentData(null);

        if (isNotNil(activeBusiness)) fetchCenters();
    }, [activeBusiness]);

    useEffect(() => {
        if (isNotNil(activeBusiness) && isNil(residentData)) fetchResidents();
    }, [residentData]);
    useEffect(() => {
        if (state && state.businessId) {
            setActiveBusiness(state.businessId);
        }
    }, [state, businessData]);
    useEffect(() => {
        if (formAlert) {
            setTimeout(() => {
                setFormAlert(false);
            }, 3000);
        }
    }, [formAlert]);

    const fetchBusinesses = useCallback(async () => {
        const res = await BusinessService.getAllBusinesses();
        if (res.success) {
            setBusinessData(res.data);
            setActiveBusiness(res.data[0]._id);
        }
    }, []);

    const fetchCenters = useCallback(async () => {
        const res = await CenterService.getAllCenters(activeBusiness);
        if (res.success) {
            setCenterData(res.data);
            setActiveCenter("All");
            fetchResidents();
        }
    }, [activeBusiness]);

    const fetchResidents = useCallback(async () => {
        const res = await ResidentService.getAllResidentsByBusiness(activeBusiness);
        if (res.success) {
            setResidentData(res.data);
        }
    }, [activeBusiness]);

    const handleToggleModal = (residentId?: string, op: FormOperation = FormOperation.CREATE) => {
        setToggleModal(true);
        setMode({ op: op, residentId: residentId });
    };

    const handleCenterChange = (e: any) => {
        setActiveCenter(e.target.value);
    };

    const childCallback = async (updated: boolean, isDelete?: boolean) => {
        if (updated) {
            if (isDelete) setMode({ ...mode, op: FormOperation.DELETE });
            setResidentData(null);
            fetchResidents();
            setToggleModal(false);
            setFormAlert(true);
        }
    };
    
    const residentCard = (residents: Resident[]) => {
        const cells: Cells = {
            columns: translate("setting.resident.table", { returnObjects: true }),
            rows: [] as any[],
        };

        cells.rows = residents?.map((resident) => {
            const birthday = getBirthday(resident.dateOfBirth);
            const { year, month, day } = birthday;
            const ct = centerData?.find((item) => item._id === resident.center?._id);
            return {
                avatar: (
                    <MDAvatar
                        src={isNotNil(resident.avatarUrl) ? resident.avatarUrl : defaultAvatar}
                        alt="avatar"
                        size="sm"
                        shadow="sm"
                    />
                ),
                id: resident._id,
                no: resident.number,
                name: resident.name,
                gender: resident.gender.charAt(0).toUpperCase() + resident.gender.slice(1),
                age: calculateAge(year, month, day).toString(),
                center:
                    resident.center?.name.length > 20
                        ? `${resident.center?.name.substring(0, 20)}...`
                        : resident.center?.name,
                type: isNil(ct) ? (
                    <></>
                ) : (
                    <MDBadge
                        variant="gradient"
                        size="md"
                        badgeContent={
                            ct.isCentralized
                                ? translate("setting.center.centralized")
                                : translate("setting.center.remote")
                        }
                        color={ct.isCentralized ? "primary" : "light"}
                        container
                        circular
                    />
                ),
                address: (
                    <MDBox display="flex" alignItems="center">
                        <Icon color="warning">home</Icon>
                        <MDTypography variant="subtitle2" ml={1}>
                            {resident.address?.length > 25
                                ? `${resident.address.substring(0, 25)}...`
                                : resident.address}
                        </MDTypography>
                    </MDBox>
                ),
                more: <More />,
            };
        });

        return <DataTable table={cells} handleOnClick={handleToggleModal} />;
    };

    const renderResidentTable = () => {
        let returnVal;
        if (activeCenter === "All" && isNotNil(residentData) && residentData.length > 0) {
            // All
            returnVal = <>{residentCard(residentData)}</>;
        } else {
            // Filter by center
            const residentByCenter = residentData?.filter((item) => item.center?._id === activeCenter);
            if (isNotNil(residentByCenter) && residentByCenter.length > 0) {
                returnVal = <>{residentCard(residentByCenter)}</>;
            }
        }

        if (residentData?.length === 0) {
            returnVal = (
                <MDNoData
                    content={translate("general.notification.noData", {
                        type: `${translate("general.type.resident")}`,
                    })}
                />
            );
        }
        return returnVal;
    };

    const renderForm = () => {
        const res = residentData?.find((item) => item._id === mode.residentId);

        if (mode.op === FormOperation.CREATE) {
            return <NewResident setToggleModal={setToggleModal} businessId={activeBusiness} callback={childCallback} />;
        } else if (mode.op === FormOperation.UPDATE) {
            return (
                <ViewResident
                    setToggleModal={setToggleModal}
                    businessId={activeBusiness}
                    resident={res}
                    callback={childCallback}
                />
            );
        }
    };
    const ModalForm = ({ toggleModal }: { toggleModal: boolean }) => {
        return toggleModal && renderForm();
    };
    return (
        <BasicLayout>
            <ModalForm toggleModal={toggleModal} />
            {formAlert && (
                <MDNotification
                    content={translate("general.notification.message", {
                        type: `${translate("general.type.resident")}`,
                        operation: `${translate(`general.operation.past.${mode.op}`)}`,
                    })}
                />
            )}
            {isNil(businessData) ? (
                <MDCircularProgress color="primary" />
            ) : (
                <>
                    <MDBox mb={3}>
                        <MDTab
                            list={businessData.map((item) => ({ key: item.name, value: item._id }))}
                            active={activeBusiness}
                            setActive={setActiveBusiness}
                        />
                    </MDBox>
                    {isNil(centerData) && isNil(residentData) ? (
                        <MDCircularProgress color="primary" />
                    ) : (
                        <>
                            <MDFilter data={centerData} handleChange={handleCenterChange} />
                            <MDBox mb={2} px={2}>
                                <AddButton
                                    content={translate("general.button.addResident")}
                                    handleToggleModal={() => handleToggleModal()}
                                    isDisabled={centerData?.length === 0}
                                />
                            </MDBox>
                            <MDBox>{renderResidentTable()}</MDBox>
                        </>
                    )}
                </>
            )}
        </BasicLayout>
    );
};

export default ResidentSettingPage;
