/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FormOperation } from "types/enums";
import { isNil, isNotNil } from "helpers/utils";

// Declaring prop types for DataTableBodyCell
interface Props {
    id: string;
    children: ReactNode;
    noBorder?: boolean;
    align?: "left" | "right" | "center";
    disabled?: boolean;
    handleOnClick: (id: string, op?: FormOperation) => void;
}

export const DataTableBodyCell: React.FC<Props> = ({ id, noBorder, align, disabled, children, handleOnClick }) => {
    return (
        <MDBox
            component="td"
            p={1.5}
            sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
                cursor: isNil(handleOnClick) ? "inherit" : "pointer",
                fontSize: size.sm,
                borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
            })}
            onClick={() => isNil(disabled) && isNotNil(handleOnClick) && handleOnClick(id, FormOperation.UPDATE)}>
            <MDBox sx={{ verticalAlign: "middle" }}>
                <MDTypography variant="subtitle2" textAlign={align}>
                    {children}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
};

// Declaring default props for DataTableBodyCell
DataTableBodyCell.defaultProps = {
    noBorder: false,
    align: "left",
};

DataTableBodyCell.whyDidYouRender = true;
export default DataTableBodyCell;
