import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// formik components
import { Formik, Form, FieldArray } from "formik";

// New settings layout schemas for form and form fields
import form from "layouts/settings/schemas/form";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSkeleton from "components/MDSkeleton";
import MDErrorMsg from "components/MDErrorMsg";
import { FormSelect, FormCheckbox, FormTimePicker } from "components/MDFormField";
import AddArrayButton from "components/MDButton/addArrayButton";
import RemoveArrayButton from "components/MDButton/removeArrayButton";
import FormLayout from "examples/LayoutContainers/FormLayout";

// Types
import { NotificationSettings, NotificationSettingsForm } from "types/setting-schema";

// Service
import UnitAlarmService from "services/unitAlarm.service";

// Types
import { RoomType } from "types/enums";

// Helpers
import { isNil, isNotNil } from "helpers/utils";
import { getFormKeys, getCamelCase } from "helpers/formUtil";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";

// i18n
import { useTranslation } from "react-i18next";

export const UnitAlarmForm: React.FC = () => {
    const navigate = useNavigate();
    const [unitAlarm, setUnitAlarm] = useState<NotificationSettings | null>(null);
    const { formId, formField } = form;
    const [error, setError] = useState<string | null>(null);
    const { weakVitalSign, fallAlarm, notInRoom, stayInRoom } = formField.unitAlarm;
    const { t: translate } = useTranslation(["common"]);
    const isFirstRender = useRef(true);
    useEffect(() => {
        const fetchUnitAlarm = async () => {
            const res = await UnitAlarmService.getUnitAlarm();
            if (res.success) {
                setUnitAlarm(res.data[0]);
            }
        };
        fetchUnitAlarm();
    }, []);

    const submitForm = async (values: any, actions: any) => {
        setError(null);
        handleUpdateAlarm(values);
        // if (res.success) {
        //     navigate("/dashboard");
        // } else {
        //     setError(res.error);
        // }

        actions.setSubmitting(false);
    };

    const handleUpdateAlarm = async (unitAlarm: NotificationSettingsForm) => {
        let body: NotificationSettings = {
            weakVitalSign: unitAlarm.weakVitalSign,
            fallAlarm: unitAlarm.fallAlarm,
            notInRoom: unitAlarm.notInRoom,
            stayInRoom: unitAlarm.stayInRoom.map((room: any) => {
                return {
                    roomType: room.roomType,
                    period: room.period,
                    hasLine: unitAlarm.stayInRoomCheck.line,
                    hasAlarmNotice: unitAlarm.stayInRoomCheck.alarm,
                };
            }),
        };

        return UnitAlarmService.updateUnitAlarm(body);
    };

    const renderAlarmTitle = (title: string) => {
        return <MDTypography variant="h5">{translate(`setting.alarms.subtitle.${title}`)}</MDTypography>;
    };

    const renderAlarmCheckbox = (field: any, value: any, stayInRoom?: boolean, stayInRoomCheck?: any) => {
        if (stayInRoom) {
            if (isFirstRender.current) {
                if (value.length === 0) {
                    stayInRoomCheck.alarm = false;
                    stayInRoomCheck.line = false;
                } else {
                    stayInRoomCheck.alarm = value[0].hasAlarmNotice;
                    stayInRoomCheck.line = value[0].hasLine;
                }
                isFirstRender.current = false;
            }
            return (
                <>
                    <Grid item xs={3}>
                        <FormCheckbox
                            {...getFormKeys(
                                {
                                    name: "stayInRoomCheck.alarm",
                                    type: "checkbox",
                                },
                                translate(`setting.alarms.alarm`),
                            )}
                            checked={stayInRoomCheck.alarm}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormCheckbox
                            {...getFormKeys(
                                {
                                    name: "stayInRoomCheck.line",
                                    type: "checkbox",
                                },
                                translate(`setting.alarms.instantMsg`),
                            )}
                            checked={stayInRoomCheck.line}
                        />
                    </Grid>
                </>
            );
        }
        return (
            <>
                <Grid item xs={3}>
                    <FormCheckbox
                        {...getFormKeys(field.hasAlarmNotice, translate(`setting.alarms.alarm`))}
                        checked={value.hasAlarmNotice}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormCheckbox
                        {...getFormKeys(field.hasLine, translate(`setting.alarms.instantMsg`))}
                        checked={value.hasLine}
                    />
                </Grid>
            </>
        );
    };

    const renderAlarmDuration = (title: string, field: any, value: any, arrayIndex?: any) => {
        const minutes = [
            0, 5, 10, 15, 20, 25, 30, 40, 50, // for 0th hour
            60, 65, 70, 75, 80, 85, 90, 100, 110, // for 1st hour
            120, 125, 130, 135, 140, 145, 150, 160, 170, // for 2nd hour
            180, 185, 190, 195, 200, 205, 210, 220, 230, // for 3rd hour
            240, 245, 250, 255, 260, 265, 270, 280, 290, // for 4th hour
            300, 305, 310, 315, 320, 325, 330, 340, 350, // for 5th hour
            360, 365, 370, 375, 380, 385, 390, 400, 410, // for 6th hour
            420, 425, 430, 435, 440, 445, 450, 460, 470, // for 7th hour
            480, 485, 490, 495, 500, 505, 510, 520, 530, // for 8th hour
            540, 545, 550, 555, 560, 565, 570, 580, 590, // for 9th hour
            600, 605, 610, 615, 620, 625, 630, 640, 650, // for 10th hour
            660, 665, 670, 675, 680, 685, 690, 700, 710, // for 11th hour
            720 // for 12th hour
          ];
        return (
            <Grid item xs={3} display="flex" alignItems="center">
                <Grid container display="flex" alignItems="center" columnSpacing={1}>
                    {title === "fall" ? (
                        <>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                                <FormSelect
                                    {...getFormKeys(field.period, "")}
                                    options={[15, 30, 45, 60].map((num: number) => ({
                                        option: `${num} ${translate(`setting.alarms.sec`)}`,
                                        value: num,
                                    }))}
                                    value={value}
                                />
                            </Grid>
                        </>
                    ) : title === "stayInRoom" ? (
                        <>
                            <Grid item xs={4}>
                                <MDTypography variant="body2">{translate(`setting.alarms.duration`)}</MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormSelect
                                    {...getFormKeys(field.period, "")}
                                    name={isNotNil(arrayIndex) ? `${arrayIndex}.period` : field.period.name}
                                    options={minutes.map((num: number) => ({
                                        option: num,
                                        value: num,
                                    }))}
                                    value={value}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MDTypography variant="body2">{translate(`setting.alarms.min`)}</MDTypography>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={4}>
                                <MDTypography variant="body2">{translate(`setting.alarms.duration`)}</MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormSelect
                                    {...getFormKeys(field.period, "")}
                                    name={isNotNil(arrayIndex) ? `${arrayIndex}.period` : field.period.name}
                                    options={[15, 30, 45, 60, 90, 120, 240, 360].map((num: number) => ({
                                        option: num,
                                        value: num,
                                    }))}
                                    value={value}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MDTypography variant="body2">{translate(`setting.alarms.min`)}</MDTypography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderAlarmTimeRange = (field: any, value: DateRange<Dayjs>, value_: any) => {
        return (
            <Grid item xs={8}>
                <FormTimePicker
                    {...getFormKeys(field.timeRange, translate(`setting.alarms.timeRange`))}
                    value={value}
                    value_={value_}
                />
            </Grid>
        );
    };

    function formatString(str: string): string {
        return str.replace(" ", "").replace(/r/g, "R");
    }

    if (isNil(unitAlarm)) return <MDSkeleton />;

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ ...unitAlarm, stayInRoomCheck: { alarm: false, line: false } }}
                        onSubmit={submitForm}>
                        {({ values, isSubmitting }) => (
                            <Form id={formId} autoComplete="off">
                                <FormLayout header={translate("setting.alarms.unitAlarm")}>
                                    <MDBox mt={1.625}>
                                        <MDBox>
                                            {renderAlarmTitle("vital")}
                                            <Grid container mb={3} spacing={2}>
                                                {renderAlarmCheckbox(weakVitalSign, values.weakVitalSign)}
                                                {renderAlarmDuration(
                                                    "vital",
                                                    weakVitalSign,
                                                    values.weakVitalSign.period,
                                                )}
                                            </Grid>
                                        </MDBox>
                                        <MDBox>
                                            {renderAlarmTitle("fall")}
                                            <Grid container mb={3} spacing={2}>
                                                {renderAlarmCheckbox(fallAlarm, values.fallAlarm)}
                                                {renderAlarmDuration("fall", fallAlarm, values.fallAlarm.period)}
                                            </Grid>
                                        </MDBox>
                                        <MDBox>
                                            {renderAlarmTitle("notInRoom")}
                                            <Grid container mb={3} spacing={2}>
                                                {renderAlarmCheckbox(notInRoom, values.notInRoom)}
                                                {renderAlarmDuration("notInRoom", notInRoom, values.notInRoom.period)}
                                                {renderAlarmTimeRange(
                                                    notInRoom,
                                                    [
                                                        dayjs(`${values.notInRoom.st}:${values.notInRoom.sm}`, "HH:mm"),
                                                        dayjs(`${values.notInRoom.et}:${values.notInRoom.em}`, "HH:mm"),
                                                    ],
                                                    values.notInRoom,
                                                )}
                                            </Grid>
                                        </MDBox>
                                        <MDBox>
                                            {/* prolongInRoom Array */}
                                            {values.stayInRoom.length > 0 && (
                                                <FieldArray
                                                    name="stayInRoom"
                                                    render={(arrayHelpers) => {
                                                        return (
                                                            <MDBox my={3}>
                                                                <MDBox
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MDTypography variant="h5">
                                                                        {translate(
                                                                            `setting.alarms.subtitle.prolongInRoom`,
                                                                        )}
                                                                    </MDTypography>
                                                                    {values.stayInRoom.length < 4 && (
                                                                        <AddArrayButton
                                                                            content={`${translate(
                                                                                `general.button.addRoom`,
                                                                            )}`}
                                                                            ary={arrayHelpers as any}
                                                                            obj={{
                                                                                roomType: formatString(
                                                                                    RoomType.LIVING_ROOM,
                                                                                ),
                                                                                period: 240,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </MDBox>
                                                                <Grid container mb={3} spacing={2}>
                                                                    {renderAlarmCheckbox(
                                                                        stayInRoom,
                                                                        values.stayInRoom,
                                                                        true,
                                                                        values.stayInRoomCheck,
                                                                    )}
                                                                </Grid>

                                                                {values.stayInRoom &&
                                                                    values.stayInRoom.map((room, index) => (
                                                                        <MDBox key={index} my={1}>
                                                                            <Grid
                                                                                container
                                                                                alignItems="end"
                                                                                columnSpacing={2}>
                                                                                <Grid item xs={0.5}>
                                                                                    <MDTypography variant="h6">
                                                                                        {index + 1}.
                                                                                    </MDTypography>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={3}>
                                                                                    <FormSelect
                                                                                        options={[
                                                                                            RoomType.LIVING_ROOM,
                                                                                            RoomType.BEDROOM,
                                                                                            RoomType.BATHROOM,
                                                                                            RoomType.KITCHEN,
                                                                                        ].map((item) => ({
                                                                                            option: translate(
                                                                                                `setting.unit.roomType.${getCamelCase(
                                                                                                    item,
                                                                                                )}`,
                                                                                            ),
                                                                                            value: formatString(item),
                                                                                        }))}
                                                                                        type="text"
                                                                                        // name={`stayInRoom.rooms.${index}.room`}
                                                                                        name={`stayInRoom[${index}].roomType`}
                                                                                        value={room.roomType}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={1}></Grid>
                                                                                {renderAlarmDuration(
                                                                                    "stayInRoom",
                                                                                    notInRoom,
                                                                                    room.period,
                                                                                    `stayInRoom[${index}]`,
                                                                                )}
                                                                                {values.stayInRoom.length > 1 && (
                                                                                    <RemoveArrayButton
                                                                                        ary={arrayHelpers as any}
                                                                                        index={index}
                                                                                    />
                                                                                )}
                                                                            </Grid>
                                                                        </MDBox>
                                                                    ))}
                                                            </MDBox>
                                                        );
                                                    }}
                                                />
                                            )}
                                        </MDBox>
                                    </MDBox>
                                    {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                                    <MDBox display="flex" justifyContent="end" mt={2} width="100%">
                                        <MDButton
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="gradient"
                                            circular={false}>
                                            {translate("general.button.save")}
                                        </MDButton>
                                    </MDBox>
                                </FormLayout>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};
