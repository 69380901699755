import { useMemo, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import MDBox from "components/MDBox";
import DataTableHeadCell from "./DataTableHeadCell";

import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
import DataTableBodyCell from "./DataTableBodyCell";
import { isNotNil } from "helpers/utils";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";
import { useTranslation } from "react-i18next";
import MDInput from "components/MDInput";
import MDCircularProgress from "components/MDCircularProgress";

interface Props {
    entriesPerPage?:
        | false
        | {
              defaultValue: number;
              entries: number[];
          };
    canSearch?: boolean;
    showTotalEntries?: boolean;
    table: {
        columns: { [key: string]: any }[];
        rows: { [key: string]: any }[];
    };
    pagination?: {
        variant: "contained" | "gradient";
        color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
    };
    isSorted?: boolean;
    noEndBorder?: boolean;
    handleOnClick?: () => void;
    isHoverRow?: boolean;
    CustomPage?: number;
    count?: number;
    handlePageChange?: (page: number) => void;
    centerHeader?: boolean;
    loading?: boolean;
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void;
    sorted?: false | "none" | "asce" | "desc";
    order: Order;
    orderBy: string;
}
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    if (!array || array.length === 0) {
        return [];
    }
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
type Order = false | "asce" | "desc" | "none";
function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const DataTable = (props: Props) => {
    const {
        showTotalEntries,
        table,
        pagination,
        isSorted,
        noEndBorder,
        handleOnClick,
        isHoverRow,
        handlePageChange,
        count,
        CustomPage,
        centerHeader,
        loading,
    } = props;
    const { t: translate } = useTranslation(["common"]);
    const columns = useMemo<any>(() => table.columns, [table]);
    const data = useMemo<any>(() => table.rows, [table]);
    const [order, setOrder] = useState<Order>("none");
    const [orderBy, setOrderBy] = useState("none");
    //if CustomPage is provided, use it, else use 0
    const [page, setPage] = useState(CustomPage - 1 || 0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // Avoid a layout jump when reaching the last page with empty rows.
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        let newOrder: Order = "none";
        if (orderBy === property) {
            // Cycle order if the property is already being ordered
            newOrder = order === "asce" ? "desc" : order === "desc" ? "none" : "asce";
        } else {
            // Default to ascending order for new property
            newOrder = "asce";
        }
        setOrder(newOrder);
        setOrderBy(property);
    };
    //event: unknown,
    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        count !== undefined && CustomPage
            ? 10 - data.length
            : page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - data.length)
            : 0;

    const visibleRows = useMemo(() => {
        if (count !== undefined && CustomPage) {
            return stableSort(data, getComparator(order, orderBy));
        } else {
            return stableSort(data, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
        }
    }, [count, CustomPage, data, order, orderBy, page, rowsPerPage]);

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
        const handleActiveSortIcon = (id: string) => {
            if (order === "none") {
                return "info";
            }
            if (order === "asce" && orderBy === id) {
                return "text";
            }
            if (order === "desc" && orderBy === id) {
                return "text";
            }
            return "info";
        };
        return columns.map((headCell: any, key: any) => (
            <MDBox
                component="th"
                key={headCell.Header}
                p={1.5}
                disabled={!headCell.disable}
                onClick={!headCell.disable && headCell.Header ? createSortHandler(headCell?.accessor) : undefined}
                sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
                    borderBottom: `${borderWidth[1]} solid ${light.main}`,
                })}>
                <MDBox
                    // {...rest}
                    position="relative"
                    display="flex"
                    // textAlign={align}
                    opacity={0.7}
                    sx={({ typography: { size, fontWeightBold } }: Theme) => ({
                        fontSize: size.xxs,
                        fontWeight: fontWeightBold,
                        textTransform: "uppercase",
                        cursor: order && !headCell.disable && "pointer",
                        userSelect: order && "none",
                        justifyContent: centerHeader && "center",
                    })}>
                    <MDTypography variant="h6" color="lightBlue">
                        {headCell.Header}
                    </MDTypography>
                    {headCell.Header && !headCell.disable && (
                        <MDBox
                            display="flex"
                            alignItems="center"
                            sx={({ typography: { size } }: any) => ({
                                fontSize: size.lg,
                            })}>
                            <MDBox
                                position="absolute"
                                color={handleActiveSortIcon(headCell?.accessor)}
                                opacity={order === "asce" && orderBy === headCell?.accessor ? 1 : 0.5}
                                sx={{ transform: "translateY(-15%)" }}>
                                <Icon>arrow_drop_up</Icon>
                            </MDBox>
                            <MDBox
                                position="absolute"
                                color={orderBy === headCell?.accessor ? (order === "desc" ? "text" : "info") : "info"}
                                opacity={order === "desc" && orderBy === headCell?.accessor ? 1 : 0.5}
                                sx={{ transform: "translateY(15%)" }}>
                                <Icon>arrow_drop_down</Icon>
                            </MDBox>
                        </MDBox>
                    )}
                </MDBox>
            </MDBox>
        ));
    }
    //if count is not provided, use data.length, else use count
    const rowCount = count ? count : data.length;
    const pageOptions = useMemo(
        () => Array.from({ length: Math.ceil(rowCount / rowsPerPage) }, (_, i) => i),
        [rowCount, rowsPerPage],
    );
    const renderPagination = pageOptions.map((option: any) => (
        <MDPagination
            item
            key={option}
            onClick={() => {
                handleChangePage(Number(option));
                //custom function
                handlePageChange && handlePageChange(Number(option) + 1);
            }}
            active={page === option}>
            {option + 1}
        </MDPagination>
    ));
    // Setting the entries starting point
    const entriesStart = page === 0 ? page + 1 : page * rowsPerPage + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (page === 0) {
        entriesEnd = rowsPerPage;
    } else if (page === pageOptions.length - 1) {
        entriesEnd = data.length;
    } else {
        entriesEnd = rowsPerPage * (page + 1);
    }
    const canPreviousPage = page > 0;
    const canNextPage = page < pageOptions.length - 1;
    const previousPage = () => {
        if (canPreviousPage) {
            handleChangePage(page - 1);
            handlePageChange && handlePageChange(page - 1 + 1);
        }
    };
    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option: any) => option + 1);
    const handleInputPagination = ({ target: { value } }: any) =>
        value > pageOptions.length || value < 0 ? handleChangePage(0) : handleChangePage(Number(value));
    const handleInputPaginationValue = ({ target: value }: any) => handleChangePage(Number(value.value - 1));
    const nextPage = () => {
        if (canNextPage) {
            handleChangePage(page + 1);
            handlePageChange && handlePageChange(page + 1 + 1);
        }
    };
    return (
        <TableContainer component={Paper} sx={{ boxShadow: "none", px: 1 }}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <MDBox component="thead">
                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                </MDBox>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={8} style={{ padding: 0 }}>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <MDCircularProgress size={20} />
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {visibleRows.map((_data: any, keys: any) => {
                                const cells = Object.keys(_data)
                                    .filter((key) => key !== "id") // Exclude 'id'
                                    .map((key) => (
                                        <DataTableBodyCell
                                            key={key}
                                            id={_data.id}
                                            noBorder={noEndBorder && data.length - 1 === keys}
                                            disabled={_data[key]?.props?.onClick && _data[key].props?.onClick}
                                            handleOnClick={isNotNil(handleOnClick) ? handleOnClick : null}
                                            children={_data[key]}
                                        />
                                    ));

                                return <TableRow key={keys}>{cells}</TableRow>;
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
            <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}>
                {showTotalEntries && (
                    <MDBox mb={{ xs: 3, sm: 0 }}>
                        <MDTypography variant="button" color="lightblue" fontWeight="regular">
                            {translate("general.state.totalEntries", {
                                rowsLength: count ? count : data.length,
                                entriesStart: entriesStart,
                                entriesEnd: entriesEnd,
                            })}
                        </MDTypography>
                    </MDBox>
                )}
                {pageOptions.length > 1 && (
                    <MDPagination
                        variant={pagination?.variant ? pagination.variant : "gradient"}
                        color={pagination?.color ? "pagination.color " : "info"}>
                        {canPreviousPage && (
                            <MDPagination item onClick={() => previousPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </MDPagination>
                        )}
                        {renderPagination.length > 6 ? (
                            <MDBox width="5rem" mx={1}>
                                <MDInput
                                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                                    value={customizedPageOptions[page]}
                                    onChange={(event: any) => {
                                        handleInputPagination(event);
                                        handleInputPaginationValue(event);
                                    }}
                                />
                            </MDBox>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <MDPagination item onClick={() => nextPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </MDPagination>
                        )}
                    </MDPagination>
                )}
            </MDBox>
        </TableContainer>
    );
};

// Declaring default props for DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
};
export default DataTable;
