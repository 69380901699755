export const enum FormType {
    BUSINESS = "business",
    CENTER = "center",
    UNIT = "unit",
    RESIDENT = "resident",
    ACCOUNT = "account",
    MEASUREMENT = "measurement",
    SURVEY = "survey",
}

export const enum FormOperation {
    CREATE = "create",
    UPDATE = "update",
    VIEW = "view",
    DELETE = "delete",
}

export const enum RoomType {
    LIVING_ROOM = "living room",
    BEDROOM = "bedroom",
    BATHROOM = "bathroom",
    KITCHEN = "kitchen",
}

export const getIconForRoomType = (type: RoomType): string => {
    switch (type) {
        case RoomType.LIVING_ROOM:
            return "weekend";
        case RoomType.KITCHEN:
            return "dining";
        case RoomType.BATHROOM:
            return "bathtub";
        case RoomType.BEDROOM:
            return "hotel";
    }
};

export const enum BiometricsType {
    BLOOD_PRESSURE = "bloodPressure",
    BLOOD_SUGAR = "bloodSugar.level",
    PULSE = "pulse",
    BLOOD_OXYGEN = "bloodOxygen",
    TEMPERATURE = "temperature",
}

export const enum RoomTable {
    RESIDENT = "resident",
    STATUS = "status",
    ROOM = "room",
    NAME = "name",
    BEDSTATUS = "bedStatus",
    HRRR = "hrrr",
    ROOMSTATUS = "roomStatus",
    NOTINROOM = "notInRoom",
    DEVICESTATUS = "deviceStatus",
    BLOODPRESSURE = "bloodPressure",
    BLOODSUGAR = "bloodSugar",
    TEXTREPORT = "textReport",
    OWNERREPORT = "ownerReport",
    STARTTIME = "startTime",
    ENDTIME = "endTime",
}

export const enum TimeRange {
    WEEK = "week",
    TWO_WEEK = "twoWeek",
    MONTH = "month",
    YEAR = "year",
}

export const enum DeviceTable {
    NO = "no",
    DEVICETYPE = "deviceType",
    ROOMTYPE = "roomType",
    STATUS = "status",
    SN = "sn",
    UPDATETIME = "updateTime",
}