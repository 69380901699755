import { ReactNode } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import { ToggleProp } from "types/state";
import { isNil } from "helpers/utils";

// Declaring props types for BasicLayout
interface Props extends ToggleProp {
    children: ReactNode;
    type: string;
    isDownload?: () => Promise<void>;
}

export const MDModal: React.FC<Props> = ({ children, type, setToggleModal, setImageModal, isDownload }) => {
    return (
        <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ position: "fixed", left: 0, top: 0, zIndex: 50, width: "100vw", height: "100vh" }}>
            <MDBox
                onClick={() => {
                    if (type === "form") setToggleModal(false);
                    else setImageModal(null);
                }}
                sx={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                }}
                bgColor={`rgba(0, 0, 0, ${type === "form" ? "0.2" : "0.4"})`}></MDBox>
            <MDBox
                width={{ xs: "90%", md: `${type === "form" ? "70%" : "90%"}` }}
                sx={{ position: "relative", maxWidth: `${type === "form" ? "800px" : "1000px"}`, zIndex: 50 }}>
                <MDBox m={{ xs: 2, md: 5 }} sx={{ position: "absolute", right: "0", zIndex: 10 }}>
                    <MDBox
                        shadow="md"
                        display="flex"
                        alignItems="center"
                        onClick={() => {
                            if (type === "form") setToggleModal(false);
                            else setImageModal(null);
                        }}
                        p={1}
                        sx={{ cursor: "pointer", backgroundColor: "#E4E9F3", borderRadius: "50%" }}>
                        <Icon fontSize="small">close</Icon>
                    </MDBox>
                    {isDownload != null && (
                        <MDBox  sx={{ position: "absolute", left: "-50px",top:"10px", zIndex: 10 }}>
                            <Icon
                                fontSize="medium"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    isDownload();
                                }}>
                                download
                            </Icon>
                        </MDBox>
                    )}
                </MDBox>
                {children}
            </MDBox>
        </MDBox>
    );
};

export default MDModal;
