// background images
import Shape1 from "assets/images/shapes/auth-shape1.png";
import Shape2 from "assets/images/shapes/auth-shape2.png";
import Shape3 from "assets/images/shapes/auth-shape3.png";

export const basicLayoutStyle = {
    container: {
        position: "relative",
        background: "#F9F9FF",
        pt: 20,
        width: "100%",
        height: "100vh",
        mx: "auto",
    },
    backgroundImage1: {
        background: `url(${Shape1}) no-repeat bottom right / cover`,
        position: "absolute",
        top: 0,
        left: 0,
        width: "46vw",
        maxWidth: "600px",
        height: "50%",
    },
    backgroundImage2: {
        background: `url(${Shape2}) no-repeat center center / cover`,
        position: "absolute",
        bottom: "35px",
        left: "20vw",
        width: "98px",
        height: "140px",
    },
    backgroundImage3: {
        background: `url(${Shape3}) no-repeat top left / cover`,
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "22vw",
        height: "20%",
    },
    card: {
        position: "relative",
        zIndex: 1,
        padding: 6,
        paddingTop: 10,
        paddingBottom: 5,
        maxWidth: "800px",
        width: "100%",
    },
    imageContainer: {
        position: "absolute",
        zIndex: 2,
        top: "-35px",
        left: "50%",
        transform: "translateX(-50%)",
        mx: "auto",
    },
    image: {
        width: "70px",
        transition: "0.2s linear",
    },
};

export const dialogHeaderStyle = {
    title: {
        fontSize: "34px",
        fontWeight: "medium",
    },
};

export const errorStyle = {
    container: {
        width: "100%",
        borderRadius: "5px",
        padding: "12px 16px",
    },
};
