import { User } from "types/user-schema";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDNoData from "components/MDNoData";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { isNotNil } from "helpers/utils";
// i18n
import { useTranslation } from "react-i18next";
import MDAvatar from "components/MDAvatar";
import { AlertRecord } from "types/alert-schema";
import HomeIcon from "@mui/icons-material/Home";
// import { DeviceTable } from "types/enums";
import { Theme } from "@mui/material";
import AlarmService from "services/alarm.service";
import { useEffect, useState } from "react";
import MDNotification from "components/MDNotification";
import { id } from "date-fns/locale";
interface Cells {
    columns: { Header: string; accessor: string; disable?: boolean }[];
    rows: {
        no: JSX.Element;
        roomType: JSX.Element;
        status: JSX.Element;
        sn: JSX.Element;
        updateTime: JSX.Element;
        deviceType: JSX.Element;
    }[];
}

interface Props {
    list: any[];
    // callback: (updated: boolean) => void;
}

export const DeviceTable: React.FC<Props> = ({ list }) => {
    const { t: translate } = useTranslation(["common"]);


    const renderTable = () => {
        if (list?.length === 0) {
            return (
                <MDNoData
                    content={translate("general.notification.noData", {
                        type: `${translate("general.type.device")}`,
                    })}
                />
            );
        }
        const cells: Cells = {
            columns: translate("device.table", { returnObjects: true }),
            rows: [] as any[],
        };
        const renderCellContent = (content: React.ReactNode) => (
            <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>{content}</MDBox>
        );
        const renderCellStatus = (content: React.ReactNode,status:boolean) => (
            <MDBox sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <MDBox sx={{padding:"3px 10px",background:status?"#EF463B":"#69BF69",color:"#fff",fontSize:"12px",borderRadius:"20px"}}>
                    {content}
                </MDBox>
            </MDBox>
        );
        cells.rows = list?.flatMap((room) =>
            room.disconnectData.map((device: any) => {
                return {
                    id: device.deviceid,
                    no: `${room.floor}-${room.roomName}`,
                    deviceType: translate(`device.deviceType.${device.type}`),
                    roomType: translate(`device.roomType.${device.space ? device.space : "bedRoom"}`),
                    status: renderCellStatus(translate(`device.status.${device.disconnected}`),device.disconnected),
                    sn: device.deviceid,
                    // updateTime: renderCellContent(renderTime(device.updateDate)),
                };
            }),
        );

        return <DataTable table={cells} />;
    };

    return (
        <MDBox>
            {renderTable()}
        </MDBox>
    );
};

export default DeviceTable;
