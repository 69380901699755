import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/userContext";

// Components
import BasicLayout from "examples/LayoutContainers/BasicLayout";

import AlarmService from "services/alarm.service";

// Types
import { Business, Center } from "types/setting-schema";
import { FormOperation, FormType } from "types/enums";

// Helpers
import { isNil, isNotNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";
import { AlertRecord } from "types/alert-schema";
import centerService from "services/center.service";
import CenterCard from "./components/CenterCard";
import MDCircularProgress from "components/MDCircularProgress";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDNewFilter from "components/MDNewFilter";
import businessService from "services/business.service";
import MDTab from "components/MDTab";
import Report from "layouts/report/Report";
import MDTypography from "components/MDTypography";

export interface CenterWithAlerts extends Center {
    todayAlerts?: AlertRecord[];
    totalAlertsYesterday?: number;
    onlineResidents: number;
    businessId: string;
    blur?: boolean;
}

export const CenterInfoPage: React.FC = () => {
    const { currentUser } = useAuth();
    const [centerData, setCenterData] = useState<CenterWithAlerts[]>(null);
    const [businessData, setBusinessData] = useState<Business[]>(null);
    const [activeBusiness, setActiveBusiness] = useState<string>("64475a199d4b4a2c835a5d23");
    const [activeSetting, setActiveSetting] = useState("live");
    let state = useLocation().state as { businessId: string } | null;
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        if (state && state.businessId) {
            fetchCenter(state.businessId);
            setActiveBusiness(state.businessId);
        } else if (currentUser?.role === "rel-admin") {
            fetchCenter("all");
        } else {
            fetchCenter(currentUser?.businessId);
        }
    }, [state, currentUser]);
    useEffect(() => {
        fetchBusinesses();
    }, []);
    const fetchCenter = async (id: string) => {
        const res = await centerService.getAllCentersWithOnlineStatus(id);
        if (res.success) {
            const data = res.data;
            setCenterData(data); // Set the center data immediately

            data.forEach(async (center: CenterWithAlerts) => {
                const alerts = await fetchAlerts(center._id);
                setCenterData((prevData) =>
                    prevData.map((item) =>
                        item._id === center._id
                            ? {
                                  ...item,
                                  todayAlerts: alerts?.todayAlerts,
                                  totalAlertsYesterday: alerts?.totalAlertsYesterday,
                              }
                            : item,
                    ),
                );
            });
        }
    };

    const fetchBusinesses = async () => {
        const res = await businessService.getAllBusinesses();
        setBusinessData(res.data);
        // setActiveBusiness(res.data[0]?._id);
    };
    const fetchAlerts = async (id: string) => {
        const res = await AlarmService.getTodayBusinessAlarm(
            "centerId",
            id,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
        if (res.success) return res.data;
    };

    const renderCenterCard = () => {
        // Filter centers based on the activeBusiness value
        const filteredCenters = centerData.filter((item: CenterWithAlerts) => {
            if (activeBusiness === "All") {
                return item?.business?._id === "64475a199d4b4a2c835a5d23";
            }
            return item.business?._id === activeBusiness;
        });

        // Common Grid container
        const renderGrid = (items: CenterWithAlerts[]) => (
            <Grid container spacing={3} maxWidth={1920} display={{ xxl: "flex" }}>
                {items.map((item: CenterWithAlerts) => (
                    <CenterCard
                        key={item._id}
                        businessId={item?.business?._id}
                        blur={item?.business?._id !== "64475a199d4b4a2c835a5d23"}
                        {...item}
                    />
                ))}
            </Grid>
        );

        // Render for "All" case
        if (activeBusiness === "All") {
            return (
                <>
                    {renderGrid(filteredCenters)}

                    <Grid
                        container
                        spacing={3}
                        maxWidth={1920}
                        display={{ xxl: "flex" }}
                        pt={5}
                        sx={{ position: "relative" }}>
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1001,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <MDTypography variant="h5" color="textSecondary">
                                {translate("auth.unauthorized")}
                            </MDTypography>
                        </MDBox>
                        {centerData
                            .filter((item: CenterWithAlerts) => item.business?._id !== "64475a199d4b4a2c835a5d23")
                            .map((item: CenterWithAlerts) => (
                                <CenterCard
                                    key={item._id}
                                    businessId={item?.business?._id}
                                    blur={item?.business?._id !== "64475a199d4b4a2c835a5d23"}
                                    {...item}
                                />
                            ))}
                    </Grid>
                </>
            );
        } else {
            return (
                <Grid
                    container
                    spacing={3}
                    maxWidth={1920}
                    display={{ xxl: "flex" }}
                    sx={{
                        position: "relative",
                    }}>
                    {activeBusiness !== "64475a199d4b4a2c835a5d23" ? (
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1001,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backdropFilter: "blur(10px)",
                            }}>
                            <MDTypography variant="h5" color="textSecondary">
                                {translate("auth.unauthorized")}
                            </MDTypography>
                        </MDBox>
                    ) : null}
                     
                    {centerData
                        .filter((item: CenterWithAlerts) => {
                            if (activeBusiness === "All") return true;
                            if (item.business) {
                                return item.business._id === activeBusiness;
                            }
                            return false;
                        })
                        .map((item: CenterWithAlerts) => (
                            <CenterCard
                                key={item._id}
                                businessId={item?.business?._id}
                                blur={item?.business?._id != "64475a199d4b4a2c835a5d23"}
                                {...item}
                            />
                        ))}
                </Grid>
            );
        }
    };

    const handleBusinessChange = (e: any) => {
        setActiveBusiness(e.target.value);
    };

    const renderReport = () => {
        return (
            <Grid container spacing={3} maxWidth={1920} display={{ xxl: "flex" }}>
                <MDBox
                    sx={{
                        background: "#FFFFFF",
                        // minHeight: "500px",
                        borderRadius: "12px",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                        position: "relative",
                        width: "100%",
                        margin: "24px",
                        // padding: "24px",
                    }}>
                    <Report
                        businessId={
                            activeBusiness !== "All"
                                ? activeBusiness !== "64475a199d4b4a2c835a5d23"
                                    ? "123"
                                    : "64475a199d4b4a2c835a5d23"
                                : "64475a199d4b4a2c835a5d23" // Replace with appropriate default value or handling
                        }
                        centerId={"All"}
                    />
                </MDBox>
            </Grid>
        );
    };

    return (
        <BasicLayout>
            {isNil(centerData) ? (
                <MDCircularProgress color="primary" />
            ) : (
                <MDBox>
                    <MDBox mb={3}>
                        <MDTab
                            list={[
                                { key: translate("info.realTimeInfo.live"), value: "live" },
                                { key: translate("info.realTimeInfo.report"), value: "report" },
                            ]}
                            active={activeSetting}
                            scrollable={false}
                            setActive={setActiveSetting}
                        />
                    </MDBox>
                    <MDBox display="flex">
                        <MDNewFilter
                            value={activeBusiness}
                            data={businessData}
                            handleChange={handleBusinessChange}
                            mb={4}
                            type={0}
                        />
                    </MDBox>
                    {activeSetting === "live" && renderCenterCard()}
                    <Grid container spacing={3} maxWidth={1920} display={{ xxl: "flex" }}>
                        {activeSetting === "report" && renderReport()}
                    </Grid>
                </MDBox>
            )}
        </BasicLayout>
    );
};

export default CenterInfoPage;
