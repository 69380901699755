import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { isNotNil } from "helpers/utils";

import MDTypography from "components/MDTypography";

interface Props {
    address?: string;
    floor: number;
}

export const UnitAddress: React.FC<Props> = ({ address, floor }) => {
    return (
        <Grid item xs={4} display="flex" alignItems="center">
            <Icon fontSize="medium">home</Icon>
            {isNotNil(floor) && <MDTypography>{floor}F</MDTypography>}
            {isNotNil(floor) && isNotNil(address) && <MDTypography> ,</MDTypography>}
            {isNotNil(address) && <MDTypography>{address}</MDTypography>}
        </Grid>
    );
};

export default UnitAddress;
