/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base breakpoints for the Material Dashboard 2 PRO React TSUI Dashboard PRO Material.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Material Dashboard 2 PRO React TSUI Dashboard PRO Material using thie file.
 */

const breakpoints = {
    values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1200,
        xl: 1440,
        xxl: 1920,
    },
};

export default breakpoints;
