interface AlarmSetting {
    parent_id: string;
    fallAlarm: {
        sensitivity: number;
        hasAlarmNotice: boolean;
        hasLine: boolean;
    };
    notInRoom: {
        st: string;
        et: string;
        sm: string;
        em: string;
        period: number;
        hasAlarmNotice: boolean;
        hasLine: boolean;
    };
    stayInRoom: Array<{
        roomType: string | null;
        period: number;
        hasAlarmNotice: boolean;
        hasLine: boolean;
    }>;
    weakVitalSign: {
        period: number;
        hasAlarmNotice: boolean;
        hasLine: boolean;
    };
}

interface DeviceXInfo {
    _idx: number;
    deviceid: string;
    space: string;
    installation: string;
}

interface AndarXSetting {
    floor: string;
    roomName: string;
    roomType: string;
    deviceXinfo: DeviceXInfo[];
}

interface BodyTemp {
    a: number | null;
    b: number | null;
}

interface AdditionInfo {
    Curtain: number;
    Bedridden: number;
    Air_Mattress: number;
    noVital: number;
}

interface BedInfo {
    id: string;
    mom: string;
    height: number | null;
    weight: number | null;
    notice: string | null;
    identityNumber: string | null;
    bed: string;
    sex: string;
    diseases: string[];
    birthday: string;
    checkInDate: string;
    checkOutDate: string;
    bodyTemp: BodyTemp;
    actionLevel: number | null;
    isFall: boolean | null;
    additionInfo: AdditionInfo;
    braceletSerial1: string | null;
    braceletSerial2: string | null;
}

export enum BedType {
    Single = "singleRoom",
}

export interface Data {
    alarmSetting: AlarmSetting;
    andarXSetting: AndarXSetting;
    bedInfo: BedInfo;
}
