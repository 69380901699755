/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentications/components/BasicLayout";
import { Change, Reset } from "layouts/authentications/components/FormContent";
import SubmitButton from "layouts/authentications/components/SubmitButton";
import DialogHeader from "layouts/authentications/components/DialogHeader";

// Authentication
import AuthService from "services/auth.service";

// Validate
import { containsEightChars, containsNumAndLetter } from "helpers/validations";

import { useTranslation } from "react-i18next";
import { isNotNil } from "helpers/utils";

interface PasswordModification {
    newPw: string;
    oldPw: string;
    repeatPw: string;
}

export const Basic: React.FC = () => {
    const [passwords, setPasswords] = useState<PasswordModification>({
        newPw: "",
        oldPw: "",
        repeatPw: "",
    });
    const [numAndLetter, setNumAndLetter] = useState<boolean>(false);
    const [charLength, setCharLength] = useState<boolean>(false);
    const [reset, setReset] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const { t: translate } = useTranslation(["common"]);
    const navigate = useNavigate();

    useEffect(() => {
        if (AuthService.getResetToken() !== null) setReset(true);
    }, []);

    useEffect(() => {
        setNumAndLetter(containsNumAndLetter(passwords.newPw));
        setCharLength(containsEightChars(passwords.newPw));
    }, [passwords]);

    const handlePasswordChange = (e: any) => {
        const { name, value } = e.target;
        setPasswords({ ...passwords, [name]: value });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setErrorMsg(null);
        if (reset) {
            if (!checkPasswordSame()) {
                setErrorMsg(translate("auth.error.notMatch"));
                return;
            }
            resetPassword();
        } else {
            if (checkPasswordSame()) {
                setErrorMsg(translate("auth.error.identical"));
                return;
            }
            changePassword();
        }
    };

    const changePassword = async () => {
        const pws = {
            currentPassword: passwords.oldPw,
            newPassword: passwords.newPw,
        };
        const res = await AuthService.changePassword(pws);
        if (res.success) navigate("/dashboard");
        else setErrorMsg(res.error);
    };

    const checkPasswordSame = () => {
        if (reset) {
            return passwords.newPw === passwords.repeatPw;
        }
        return passwords.newPw === passwords.oldPw;
    };

    const resetPassword = async () => {
        const res = await AuthService.resetPassword(passwords.newPw, translate("lang"));
        if (res.success) navigate("/sign-in");
        else setErrorMsg(res.error);
    };

    const renderPasswordCheck = () => {
        return (
            <MDBox ml={1} mt={5}>
                <MDTypography variant="subtitle2" color="lightBlue">
                    {translate("auth.passwordRequirement.statement")}
                </MDTypography>
                <MDBox mt={1}>
                    <List>
                        {[
                            { name: translate("auth.passwordRequirement.require1"), condition: numAndLetter },
                            { name: translate("auth.passwordRequirement.require2"), condition: charLength },
                        ].map((item, idx) => {
                            return (
                                <ListItem key={idx} sx={{ py: 1 }}>
                                    <MDTypography variant="subtitle2" color={item.condition ? "success" : "error"}>
                                        {item.name}
                                    </MDTypography>
                                </ListItem>
                            );
                        })}
                    </List>
                </MDBox>
            </MDBox>
        );
    };

    return (
        <BasicLayout>
            <DialogHeader
                title={reset ? `${translate("auth.resetPassword")}` : `${translate("auth.changePassword")}`}
            />
            <MDBox component="form" role="form" onSubmit={(e) => handleSubmit(e)}>
                {!reset ? (
                    <Change passwordChange={handlePasswordChange} />
                ) : (
                    <Reset passwordChange={handlePasswordChange} />
                )}
                {isNotNil(errorMsg) && (
                    <MDTypography variant="button" fontWeight="regular" color="error">
                        {errorMsg}
                    </MDTypography>
                )}

                {renderPasswordCheck()}
                <MDBox mt={4} mb={1}>
                    <SubmitButton
                        content={translate("auth.submit")}
                        disable={
                            !numAndLetter ||
                            !charLength ||
                            (passwords.oldPw.length === 0 && passwords.repeatPw.length === 0)
                        }
                    />
                </MDBox>
            </MDBox>
        </BasicLayout>
    );
};

export default Basic;
