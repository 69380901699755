/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DeviceTable from "layouts/device/DeviceTable";
import deviceService from "services/device.service";

export const Sales: React.FC = () => {
    const [disconnectData, setDisconnectData] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const fetchDisconnectDeviceData = async () => {
        // Fetch device data from API
        const res = await deviceService.getDisconnectDeviceData();
        if (res.success) {
            console.log(res.data);
            setDisconnectData(res.data);
        }
    };
    const fetchDeviceData = async () => {
        // Fetch device data from API
        const res = await deviceService.getDeviceData();
        if (res.success) {
            console.log(res.data);
            setData(res.data);
        }
    };
    useEffect(() => {
        fetchDeviceData();
        fetchDisconnectDeviceData();
    }, []);

    //handle the data, when both data and disconnectData are ready
    useEffect(() => {
        if (data && disconnectData) {
            const specialDeviceIds = [
                "d2bd70f0-55ef-11ef-85f9-5b765dec8351",
                "df6fc7b0-4b01-11ef-85f9-5b765dec8351",
                "222039e0-536e-11ef-85f9-5b765dec8351",
                "8ecad500-fcf4-11ec-b9c7-c9051ffe80db",
                "a41c2b30-2d39-11ef-bbec-ab837134bae0",
                "0caec4b0-2cf3-11ef-bbec-ab837134bae0",
                "203a0370-fcf6-11ec-b9c7-c9051ffe80db",
                "0fc99f20-f07e-11ec-8999-053df5604be9",
                "7a29b750-559f-11ef-85f9-5b765dec8351",
                "9c3f01b0-2d3b-11ef-bbec-ab837134bae0",
                "da285060-2506-11ed-8074-bb7d7ad82e98",
            ];
            let onlineCount = 0;
            let offlineCount = 0;
            const newData: any[] = data.map((device: any) => {
                const deviceList: any[] = [];
                if (device.deviceXinfo.length > 0) {
                    device.deviceXinfo.map((deviceX: any) => {
                        const deviceData = disconnectData.find((d: any) => d.sn === deviceX.deviceid);
                        const isSpecialDevice = specialDeviceIds.includes(device.id);
                        if (deviceData) {
                            const timestamp = deviceData.dt * 1000;
                            const currentTimeInMillis = Date.now();
                            const isDisconnected = isSpecialDevice ? false : currentTimeInMillis - timestamp > 60000;
                            if (!isDisconnected) {
                                onlineCount++;
                            } else {
                                offlineCount++;
                            }
                            deviceList.push({
                                ...deviceX,
                                type: "anderX",
                                updateData: deviceData.dt,
                                disconnected: isDisconnected,
                            });
                        } else {
                            const isDisconnected = isSpecialDevice ? false : true;
                            if (!isDisconnected) {
                                onlineCount++;
                            } else {
                                offlineCount++;
                            }
                            deviceList.push({
                                ...deviceX,
                                type: "anderX",
                                updateData: null,
                                disconnected: isDisconnected,
                            });
                        }
                    });
                }

                const deviceData = disconnectData.find((d: any) => device.deviceid === d.sn);
                const isSpecialDevice = specialDeviceIds.includes(device.id);
                console.log(isSpecialDevice, device.deviceid);
                if (deviceData) {
                    const timestamp = deviceData.dt * 1000;
                    const currentTimeInMillis = Date.now();
                    const isDisconnected = isSpecialDevice ? false : currentTimeInMillis - timestamp > 60000;

                    if (!isDisconnected) {
                        onlineCount++;
                    } else {
                        offlineCount++;
                    }
                    deviceList.push({
                        deviceid: device.deviceid,
                        type: "ander",
                        updateDate: deviceData.dt,
                        disconnected: isDisconnected,
                    });
                } else {
                    const isDisconnected = isSpecialDevice ? false : true;
                    if (!isDisconnected) {
                        onlineCount++;
                    } else {
                        offlineCount++;
                    }
                    deviceList.push({
                        deviceid: device.deviceid,
                        type: "ander",
                        updateDate: null,
                        disconnected: isSpecialDevice ? false : true,
                    });
                }

                return {
                    ...device,
                    disconnectData: deviceList,
                };
            });

            console.log("onlineCount", onlineCount);
            console.log("offlineCount", offlineCount);
            setList(newData);
        }
    }, [data, disconnectData]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <DeviceTable list={list} />
            <Footer />
        </DashboardLayout>
    );
};

export default Sales;
