import { useState, useEffect, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/userContext";

// @mui material components
import Grid from "@mui/material/Grid";

// Components
import MDBox from "components/MDBox";
import MDSkeleton from "components/MDSkeleton";
import MDErrorMsg from "components/MDErrorMsg";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { FormDatePicker, FormField, FormSelect } from "components/MDFormField";
import { PhoneField } from "components/MDFormField/phoneField";
import SubmitButtons from "components/MDButton/submitButton";
import EditButton from "components/MDButton/editButton";
import DeleteConfirmPop from "components/MDFormField/deleteConfirm";
import FormLayout from "examples/LayoutContainers/FormLayout";
import { UploadableImage } from "components/MDFormField/uploadableImage";

// formik components
import { Formik, Form, FieldArray } from "formik";

// New settings layout schemas for form and form fields
import form from "../schemas/form";
import useValidationSchema from "../schemas/validation";

// Types
import { FormOperation, FormType } from "types/enums";
import { Center } from "types/setting-schema";
import { User, isRelAdmin } from "types/user-schema";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import { Instance } from "flatpickr/dist/types/instance";
// Helpers
import { isNotNil, isNil } from "helpers/utils";
import { isEditDisabled, getFormKeys, getCountryCode, NOT_SELECTED, fileRead } from "helpers/formUtil";
import { validateEmail } from "helpers/validations";

// i18n
import { useTranslation } from "react-i18next";
import { ROLES_ARRAY, Roles } from "types/roles";

import defaultAvatar from "assets/images/defaultAvatar.svg";

import ResidentService from "services/resident.service";
import UnitAlarmService from "services/unitAlarm.service";

import { BedType } from "types/wistron-schema";
import AddArrayButton from "components/MDButton/addArrayButton";
import RemoveArrayButton from "components/MDButton/removeArrayButton";
import MDInput from "components/MDInput";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
// import utc from 'dayjs-plugin-utc';

dayjs.extend(utc);
enum LocationType {
    BEDROOM = "bedRoom",
    BATHROOM = "bathRoom",
    LIVINGROOM = "livingRoom",
    KITCHEN = "kitchen",
}

enum Installlation {
    WALL = "wall",
    CEILING = "ceiling",
    CORNER = "corner",
}
export const UserForm: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const { formId, formField } = form;
    const { room, alert, anderXDevice, residentInfo } = formField;
    const { t: translate } = useTranslation(["common"]);
    const notSelectedOption = { option: translate(`general.state.notSelected`), value: NOT_SELECTED };
    const validation = useValidationSchema();
    const [newResident, setNewResident] = useState<any>(null);
    const [wistronResident, setWistronResident] = useState<any>(null);
    const [defaultSetting, setDefaultSetting] = useState<Center | null>(null);
    const [floorInfo, setFloorInfo] = useState<any>(null);
    const [deviceXInfo, setDeviceXInfo] = useState<any>(null);
    const [deviceInfo, setDeviceInfo] = useState<any>(null);
    const navigate = useNavigate();
    const fp = useRef(null);
    
    const defaultUser = {
        resident: "",
        floor: "",
        number: "",
        roomType: "",
        devices: [
            { deviceNumber: "", location: "", installLocation: "" }, // Initial device session
        ],
        checkInDate:"",
        deviceId:""
    };
    const submitForm = async (values: any, actions: any) => {
        setError(null);

        const { resident, floor, number, roomType, devices, checkInDate, deviceId } = values;
        const res = await ResidentService.getWistronBedNum(number,floor);
        const wistronSetting = await UnitAlarmService.getWistronSetting();
        // const bedNo = res.data?.length?res.data.length:0;
        const bedNo = res.data?.length?res.data.length+1:1;
        const residentName=newResident.find((item:any)=>item._id===resident).name;
        const deviceXinfo = devices.map((device: any,index:any) => {
            return {
                _idx: index,
                deviceid: device.deviceNumber,
                space: device.location,
                installation: device.installLocation,
            };
        });
        const body={
            bedInfo:{
                id:deviceId,
                mon:residentName,
                bed:bedNo,
                checkIndate:checkInDate.utc().format()
            },
            andarXSetting:{
                floor:floor,
                roomType:roomType,
                roomName:number,
                deviceXinfo:deviceXinfo
            },
            alarmSetting: {
                parent_id: wistronSetting.data.parent_id,
                fallAlarm: wistronSetting.data.fallAlarm,
                notInRoom: wistronSetting.data.notInRoom,
                stayInRoom: wistronSetting.data.stayInRoom,
                weakVitalSign: wistronSetting.data.weakVitalSign,
            },
        }
        const result = await ResidentService.addNewWistronResident(body,resident);
        if(result.success){
            navigate("/dashboard");
        }

    };
    useEffect(() => {
        const fetchNewResident = async () => {
            try {
                const res = await ResidentService.getAllResidentWithoutWistronId();
                console.log(res.data, 999);
                if (res.data && res.data.residents) {
                    setNewResident(res.data.residents);
                } else {
                    setNewResident([]);
                }
            } catch (error) {
                console.error("Error fetching new residents:", error);
                setNewResident([]);
            }
        };
        const fetchWistronResident = async () => {
            try {
                const res = await ResidentService.getAllWistronResident();
                if (res.data) {
                    setWistronResident(res.data);
                } else {
                    setWistronResident([]);
                }
            } catch (error) {
                console.error("Error fetching Wistron residents:", error);
                setWistronResident([]);
            }
        };
        const fetchFloorInfo = async () => {
            try {
                const res = await ResidentService.getWistronFloorInfos();
                if (res.data) {
                    setFloorInfo(res.data);
                } else {
                    setFloorInfo([]);
                }
            } catch (error) {
                console.error("Error fetching floor info:", error);
                setFloorInfo([]);
            }
        };
        const fetchDeviceXInfo = async () => {
            try {
                const res = await ResidentService.getWistronLocationInfosWithAnderX();
                if (res.data && res.data.andarXdeviceList) {
                    setDeviceXInfo(res.data.andarXdeviceList);
                } else {
                    setDeviceXInfo([]);
                }
            } catch (error) {
                console.error("Error fetching device X info:", error);
                setDeviceXInfo([]);
            }
        };
        const fetchDeviceInfo = async () => {
            try {
                const res = await ResidentService.getWistronLocationInfos();
                if (res.data) {
                    setDeviceInfo(res.data);
                } else {
                    setDeviceInfo([]);
                }
            } catch (error) {
                console.error("Error fetching device info:", error);
                setDeviceInfo([]);
            }
        };
        // const getDefualtSetting = async () => {
        //     const res = await CenterService.getDefaultCenter();
        //     setDefaultSetting(res.data);
        // }
        fetchDeviceXInfo();
        fetchFloorInfo();
        fetchWistronResident();
        fetchNewResident();
        fetchDeviceInfo();
    }, []);

    const getRoomInfo = (rooms: any[], floor: string) => {
        if (!rooms || rooms.length === 0 || !floor) {
            return [{
                option: translate(`general.state.noData`),
                value: '',
                disabled: true,
            }];
        }
        console.log("get ROom Info",rooms)
        return rooms
            .filter(room => room.floor === floor)
            .map(room => ({
                option: room.roomName,
                value: room.roomName,
            }));
    };
    const getUnUsedDevice = (wistronResident: any, deviceInfo: any) => {
       
        if (!wistronResident || !deviceInfo) {
            return [{
                option: translate(`general.state.noData`),
                value: null,
                disabled: true,
            }];
        }
        // Extract used device IDs
        const usedDevice = wistronResident.map((resident: any) => resident.deviceid);
        // Filter for unused devices
        const unUsedDevice = deviceInfo.filter((device: any) => !usedDevice.includes(device.id));
        // Transform to option-value objects
        const optionValueArray = unUsedDevice.map((device: any) => ({
            option: device.id, // Assuming 'name' is the property to be used as option
            value: device.id // Use 'deviceid' as the value
        }));
        return optionValueArray;
    }
    return (
        <>
            <Grid container height="100%">
                <Grid item xs={12}>
                    <Formik
                        initialValues={{ ...defaultUser }}
                        validationSchema={validation.account[0]}
                        onSubmit={submitForm}>
                        {({ values, errors, touched, dirty, setFieldValue, handleChange, isSubmitting }) => {
                            return (
                                <Form id={formId} autoComplete="off">
                                    <FormLayout header={translate(`resident.addNewResident`)} name={""} fullWidth>
                                        {/* {operation === FormOperation.VIEW && <EditButton setOperation={setOperation} />} */}
                                        <MDBox mt={1.625}>
                                            <Grid container spacing={3} mb={3}>
                                                <Grid item xs={8}>
                                                    <Grid container pt={1} pb={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <MDTypography variant="body2">
                                                                {translate(`resident.name`)}
                                                                <span
                                                                    style={{ color: "red", verticalAlign: "text-top" }}>
                                                                    *
                                                                </span>
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormSelect
                                                                {...getFormKeys(room.resident, "")}
                                                                value={values.resident}
                                                                error={errors.resident && touched.resident}
                                                                success={values.resident.length > 0 && !errors.resident}
                                                                options={
                                                                    (newResident&&newResident.length>0)
                                                                        ? [
                                                                              ...newResident.map((resident: any) => ({
                                                                                  option: resident.name,
                                                                                  value: resident._id,
                                                                              })),
                                                                          ]
                                                                        : [{
                                                                            option: translate(`general.state.noData`),
                                                                            value: null,
                                                                            disabled: true,
                                                                        }]
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container pt={1} pb={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <MDTypography variant="body2">
                                                                {translate(`resident.floor`)}
                                                                <span
                                                                    style={{ color: "red", verticalAlign: "text-top" }}>
                                                                    *
                                                                </span>
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormSelect
                                                                {...getFormKeys(room.floor, "")}
                                                                value={values.floor}
                                                                error={errors.floor && touched.floor}
                                                                success={values.floor.length > 0 && !errors.floor}
                                                                options={
                                                                    floorInfo
                                                                        ? [
                                                                              ...Array.from(new Set(floorInfo.map((floor: any) => floor.floor))).map((uniqueFloor: any) => ({
                                                                                  option: uniqueFloor,
                                                                                  value: uniqueFloor,
                                                                              })),
                                                                          ]
                                                                        : [
                                                                              {
                                                                                  option: translate(`general.state.noData`),
                                                                                  value: null,
                                                                                  disabled: true,
                                                                              },
                                                                          ]
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container pt={1} pb={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <MDTypography variant="body2">
                                                                {translate(`resident.unit`)}
                                                                <span
                                                                    style={{ color: "red", verticalAlign: "text-top" }}>
                                                                    *
                                                                </span>
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormSelect
                                                                {...getFormKeys(room.number, "")}
                                                                value={values.number}
                                                                error={errors.number && touched.number}
                                                                success={values.number.length > 0 && !errors.number}
                                                                options={getRoomInfo(floorInfo, values.floor)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container pt={1} pb={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <MDTypography variant="body2">
                                                                {translate(`resident.roomType`)}
                                                                <span
                                                                    style={{ color: "red", verticalAlign: "text-top" }}>
                                                                    *
                                                                </span>
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormSelect
                                                                {...getFormKeys(room.roomType, "")}
                                                                value={values.roomType}
                                                                error={errors.roomType && touched.roomType}
                                                                success={values.roomType.length > 0 && !errors.roomType}
                                                                options={[
                                                                    {
                                                                        option: translate(`resident.singleBed`),
                                                                        value: BedType.Single,
                                                                    },
                                                                ]}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container pt={1} pb={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <MDTypography variant="body2">
                                                                {translate(`resident.checkInDate`)}
                                                                <span
                                                                    style={{ color: "red", verticalAlign: "text-top" }}>
                                                                    *
                                                                </span>
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={2.1}>
                                                        <FormDatePicker 
                                                            {...getFormKeys(
                                                                residentInfo.checkInDate,
                                                                "",
                                                            )}
                                                            value={dayjs(values.checkInDate)}
                                                            success={dayjs(values.checkInDate) && !errors.checkInDate}
                                                            error={errors.checkInDate}
                                                        />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container pt={4} pb={1} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <MDTypography
                                                                variant="body3"
                                                                fontWeight="bold"
                                                                sx={{ color: "#b16fd1" }}>
                                                                {translate(`resident.AnderTitle`)}
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container pt={1} pb={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <MDTypography variant="body2">
                                                                {translate(`resident.deviceSN`)}
                                                                <span
                                                                    style={{ color: "red", verticalAlign: "text-top" }}>
                                                                    *
                                                                </span>
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormSelect
                                                                type="text"
                                                                name="deviceId"
                                                                value={values.deviceId}
                                                                error={errors.deviceId && touched.deviceId}
                                                                success={values.deviceId.length > 0 && !errors.deviceId}
                                                                options={getUnUsedDevice(wistronResident, deviceInfo?.deviceList)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container pt={1} pb={1} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <MDTypography
                                                                variant="body3"
                                                                fontWeight="bold"
                                                                sx={{ color: "#b16fd1" }}>
                                                                {translate(`resident.anderXtitle`)}
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                    {/* i want below session can add by clicking the button if there has muti device */}
                                                    <FieldArray
                                                        name="devices"
                                                        render={(arrayHelpers) => {
                                                            return (
                                                                <MDBox>
                                                                    {values.devices.map(
                                                                        (device: any, index: number) => {
                                                                            return (
                                                                                <MDBox key={index} pt={1} pb={1}>
                                                                                    <Grid
                                                                                        container
                                                                                        pt={1}
                                                                                        pb={1}
                                                                                        alignItems="center">
                                                                                        <Grid item xs={4}>
                                                                                            <MDTypography variant="body2">
                                                                                                {translate(
                                                                                                    `resident.deviceXSN`,
                                                                                                )}
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "red",
                                                                                                        verticalAlign:
                                                                                                            "text-top",
                                                                                                    }}>
                                                                                                    *
                                                                                                </span>
                                                                                            </MDTypography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <FormSelect
                                                                                                name={`devices.${index}.deviceNumber`}
                                                                                                type="text"
                                                                                                value={
                                                                                                    device.deviceNumber
                                                                                                }
                                                                                                //@ts-ignore
                                                                                                error={errors.devices&&errors.devices[index]?.deviceNumber}
                                                                                                success={values.devices[index].deviceNumber.length > 0}
                                                                                                options={
                                                                                                    deviceXInfo
                                                                                                        ? [
                                                                                                              ...deviceXInfo.map(
                                                                                                                  (
                                                                                                                      resident: any,
                                                                                                                  ) => ({
                                                                                                                      option: resident,
                                                                                                                      value: resident,
                                                                                                                  }),
                                                                                                              ),
                                                                                                          ]
                                                                                                        : [{
                                                                                                            option: translate(`general.state.noData`),
                                                                                                            value: null,
                                                                                                            disabled: true,
                                                                                                        }]
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        container
                                                                                        pt={1}
                                                                                        pb={1}
                                                                                        alignItems="center">
                                                                                        <Grid item xs={4}>
                                                                                            <MDTypography variant="body2">
                                                                                                {translate(
                                                                                                    `resident.location`,
                                                                                                )}
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "red",
                                                                                                        verticalAlign:
                                                                                                            "text-top",
                                                                                                    }}>
                                                                                                    *
                                                                                                </span>
                                                                                            </MDTypography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <FormSelect
                                                                                                {...getFormKeys(
                                                                                                    room.resident,
                                                                                                    "",
                                                                                                )}
                                                                                                type="text"
                                                                                                name={`devices.${index}.location`}
                                                                                                value={device.location}
                                                                                                //@ts-ignore
                                                                                                error={errors.devices&&errors.devices[index]?.location}
                                                                                                success={
                                                                                                    device.location
                                                                                                        .length > 0 &&
                                                                                                    !device.location
                                                                                                }
                                                                                                options={[
                                                                                                    {
                                                                                                        option: translate(
                                                                                                            `resident.bedRoom`,
                                                                                                        ),
                                                                                                        value: LocationType.BEDROOM,
                                                                                                    },
                                                                                                    {
                                                                                                        option: translate(
                                                                                                            `resident.bathRoom`,
                                                                                                        ),
                                                                                                        value: LocationType.BATHROOM,
                                                                                                    },
                                                                                                    {
                                                                                                        option: translate(
                                                                                                            `resident.livingRoom`,
                                                                                                        ),
                                                                                                        value: LocationType.LIVINGROOM,
                                                                                                    },
                                                                                                    {
                                                                                                        option: translate(
                                                                                                            `resident.kitchen`,
                                                                                                        ),
                                                                                                        value: LocationType.KITCHEN,
                                                                                                    },
                                                                                                ]}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        container
                                                                                        pt={1}
                                                                                        pb={1}
                                                                                        alignItems="center">
                                                                                        <Grid item xs={4}>
                                                                                            <MDTypography variant="body2">
                                                                                                {translate(
                                                                                                    `resident.installLocation`,
                                                                                                )}
                                                                                            </MDTypography>
                                                                                        </Grid>
                                                                                        <Grid item xs={8}>
                                                                                            <FormSelect
                                                                                                {...getFormKeys(
                                                                                                    room.resident,
                                                                                                    "",
                                                                                                )}
                                                                                                type="text"
                                                                                                name={`devices.${index}.installLocation`}
                                                                                                value={
                                                                                                    device.installLocation
                                                                                                }
                                                                                                // success={values.name.length > 0 && !errors.floor}
                                                                                                options={[
                                                                                                    {
                                                                                                        option: translate(
                                                                                                            `resident.wall`,
                                                                                                        ),
                                                                                                        value: Installlation.WALL,
                                                                                                    },
                                                                                                    {
                                                                                                        option: translate(
                                                                                                            `resident.ceiling`,
                                                                                                        ),
                                                                                                        value: Installlation.CEILING,
                                                                                                    },
                                                                                                    {
                                                                                                        option: translate(
                                                                                                            `resident.corner`,
                                                                                                        ),
                                                                                                        value: Installlation.CORNER,
                                                                                                    },
                                                                                                ]}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    {index!==0&&<Grid item xs={12}>
                                                                                        <RemoveArrayButton
                                                                                            ary={arrayHelpers as any}
                                                                                            index={index}/>
                                                                                        </Grid>
                                                                                    }
                                                                                </MDBox>
                                                                            );
                                                                        },
                                                                    )}
                                                                    <MDBox
                                                                        display="flex"
                                                                        justifyContent="end"
                                                                        alignItems="center">
                                                                        {values.devices.length < 4 && (
                                                                            <AddArrayButton
                                                                                content={`${translate(
                                                                                    `general.button.addRoom`,
                                                                                )}`}
                                                                                ary={arrayHelpers as any}
                                                                                obj={{
                                                                                    deviceNumber: "",
                                                                                    location: "",
                                                                                    installLocation: "",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </MDBox>
                                                                </MDBox>
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                        {isNotNil(error) && <MDErrorMsg errorMsg={error} />}
                                        <SubmitButtons
                                            disable={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                                            // disable={true}
                                            operation={FormOperation.CREATE}
                                        />
                                    </FormLayout>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};
