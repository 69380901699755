/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate, useParams } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarDesktopMenu,
    navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav, setTransparentNavbar } from "context";

// user context
import { useAuth } from "context/userContext";
import authService from "services/auth.service";
import MDTypography from "components/MDTypography";

// i18n
import { useTranslation } from "react-i18next";
import { is } from "date-fns/locale";

// Declaring prop types for DashboardNavbar
interface Props {
    isMini?: boolean;
}

export const DashboardNavbar: React.FC<Props> = ({ isMini }) => {
    const [navbarType, setNavbarType] = useState<"fixed" | "absolute" | "relative" | "static" | "sticky">("static");
    const { currentUser, setCurrentUser } = useAuth();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
    const [openMenu, setOpenMenu] = useState<any>(false);
    const pathname = useParams();
    const route = useLocation().pathname.split("/").slice(1);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);
    const handleLogOut = () => {
        authService.logout();
        setCurrentUser(null);
        navigate("/sign-in");
    };

    const handleNavigateChangePassword = () => {
        navigate("/change-password");
    };

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}>
            <NotificationItem
                icon={<Icon>password</Icon>}
                title={`${translate("auth.changePassword")}`}
                onClick={handleNavigateChangePassword}
            />
            <NotificationItem icon={<Icon>logout</Icon>} title={`${translate("auth.logout")}`} onClick={handleLogOut} />
        </Menu>
    );

    return (
        <AppBar position={navbarType} color="inherit" sx={(theme) => navbar(theme, { transparentNavbar })}>
            <Toolbar sx={navbarContainer}>
                <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <Breadcrumbs
                        title={Object.keys(pathname).length === 0 ? route[route.length - 1] : pathname.name}
                        route={route}
                    />
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <MDBox display="flex" flexDirection="row-reverse" alignItems="center">
                            <MDBox display="flex" alignItems="center">
                                <IconButton sx={navbarIconButton} disableRipple onClick={handleOpenMenu}>
                                    <Icon>account_circle</Icon>
                                </IconButton>
                                <MDTypography variant="subtitle1">{currentUser?.name}</MDTypography>
                            </MDBox>
                            {renderMenu()}
                            <MDBox display={{ xs: "block", lg: "none" }}>
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarMobileMenu}
                                    onClick={handleMiniSidenav}>
                                    <Icon fontSize="medium">{miniSidenav ? "menu_open" : "menu"}</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
};

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
    isMini: false,
};

export default DashboardNavbar;
