import { forwardRef } from "react";

// formik components
import { ErrorMessage, Field, useFormikContext } from "formik";
import { Controller } from "react-hook-form";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { isNil, isNotNil } from "helpers/utils";

// Declaring props types for FormField
interface InputProps {
    label?: string;
    name: string;
    success?: boolean;
    value?: string | number;
    isDisplayMode?: boolean;
    [key: string]: any;
}

export const FormField: React.FC<InputProps> = ({ label, name, success, value, isDisplayMode, ...rest }) => {
    return isDisplayMode ? (
        <FormDisplay label={label} value={value.toString()} />
    ) : (
        <MDBox sx={{ position: "relative" }}>
            <Field
                {...rest}
                name={name}
                className={name}
                as={MDInput}
                sx={{ "& .MuiFormLabel-asterisk": { color: "#FF0000" } }}
                required={isNotNil(success)}
                label={label}
                value={value}
                success={success}
                fullWidth
            />
            <MDBox mt={0.75} sx={{ position: "absolute" }}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                    {(<ErrorMessage name={name} />) as any}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
};

interface FormDisplayProps {
    label: string;
    value?: string | number | boolean;
}

const FormDisplay: React.FC<FormDisplayProps> = ({ label, value }) => {
    return (
        <MDBox>
            <MDTypography variant="h6">{label}</MDTypography>
            <MDTypography variant="body2" pl={2}>
                {value}
            </MDTypography>
        </MDBox>
    );
};

// Declaring props types for FormSelect
interface SelectProps {
    label?: string;
    name: string;
    options: any[];
    type: string;
    value?: string | number | boolean;
    isDisplayMode?: boolean;
    onChange?: (e: any) => void;
    [key: string]: any;
}

export const FormSelect: React.FC<SelectProps> = ({
    label,
    name,
    options,
    type,
    value,
    isDisplayMode,
    required,
    ...rest
}) => {
    const renderOptions = options?.map((item: any, idx: number) => {
        // Option with image
        console.log("item", item);
        if (type === "image") {
            const country = item.code === "+1" ? "CA" : "TW";
            return (
                <MenuItem  key={idx} value={item.code} sx={{ display: "flex", minWidth: 0 }}>
                    <MDBox display="flex" alignItems="center">
                        <img src={require(`assets/images/icons/flags/${country}.png`)} alt={country} />
                        <MDBox ml={1}>{item.code}</MDBox>
                    </MDBox>
                </MenuItem>
            );
        }
        // Should have key value pair for id storage and localization
        else {
            return (
                <MenuItem disabled={item.disabled} key={idx} value={item.value} sx={{ display: "flex", minWidth: 0 }}>
                    {item.option}
                </MenuItem>
            );
        }
    });
    return isDisplayMode ? (
        <FormDisplay label={label} value={value} />
    ) : (
        <MDBox sx={{ position: "relative" }}>
            <FormControl variant="standard" fullWidth>
                {label && (
                    <InputLabel
                        id={label}
                        required={required}
                        sx={{
                            "& .MuiFormLabel-asterisk": { color: "red" },
                        }}>
                        {label}
                    </InputLabel>
                )}
                <Field
                    labelId={label}
                    name={name}
                    as={Select}
                    {...rest}
                    sx={{ paddingTop: "4px", paddingBottom: "5px" }}
                    color="primary">
                    {renderOptions}
                </Field>
            </FormControl>
            <MDBox mt={0.75} sx={{ position: "absolute" }}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                    {(<ErrorMessage name={name} />) as any}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
};
FormSelect.whyDidYouRender = true;
interface SwitchProps {
    label?: string;
    name: string;
    options: string[];
    value?: string | boolean;
    checked: boolean;
    isDisplayMode?: boolean;
    onChange?: (e: any) => void;
}

export const FormSwitch: React.FC<SwitchProps> = ({ label, name, value, options, checked, isDisplayMode, ...rest }) => {
    return isDisplayMode ? (
        <FormDisplay label={label} value={value} />
    ) : (
        <MDBox display="flex" alignItems="center">
            <MDTypography variant="body2">{options[0]}</MDTypography>
            <Field name={name} label={label} as={Switch} checked={checked} {...rest} />
            <MDTypography variant="body2">{options[1]}</MDTypography>
        </MDBox>
    );
};

interface DatePickerProps {
    label?: string;
    name: string;
    value: Dayjs;
    isDisplayMode?: boolean;
    error?: string;
    [key: string]: any;
}

export const FormDatePicker: React.FC<DatePickerProps> = ({ label, name, value, isDisplayMode, error, ...rest }) => {
    const { setFieldValue } = useFormikContext();

    return isDisplayMode ? (
        <FormDisplay label={label} value={value.format().substring(0, 10)} />
    ) : (
        <MDBox sx={{ position: "relative" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={label}
                    value={value}
                    onChange={(val) => {
                        setFieldValue(name, val);
                    }}
                    {...rest}
                />
            </LocalizationProvider>
            <MDBox mt={0.75} sx={{ position: "absolute" }}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                    {error}
                </MDTypography>
            </MDBox>
        </MDBox>
    );
};

interface TimePickerProps {
    label?: string;
    name: string;
    value: DateRange<Dayjs>;
    value_?: any;
}

export const FormTimePicker: React.FC<TimePickerProps> = ({ label, name, value, value_, ...rest }) => {
    const { setFieldValue } = useFormikContext();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MultiInputTimeRangeField
                slotProps={{
                    textField: ({ position }) => ({
                        label: position === "start" ? "From" : "To",
                    }),
                }}
                defaultValue={value}
                onChange={(val) => {
                    if (value_) {
                        value_.st = String(val[0].hour()).padStart(2, "0");
                        value_.et = String(val[1].hour()).padStart(2, "0");
                        value_.sm = String(val[0].minute()).padStart(2, "0");
                        value_.em = String(val[1].minute()).padStart(2, "0");
                    } else {
                        setFieldValue(name, val);
                    }
                }}
                {...rest}
            />
        </LocalizationProvider>
    );
};

interface CheckboxProps {
    label?: string;
    name: string;
    value?: string | boolean;
    checked?: boolean;
    isDisplayMode?: boolean;
    disabled?: boolean;
}

export const FormCheckbox: React.FC<CheckboxProps> = ({
    label,
    name,
    value,
    checked,
    isDisplayMode,
    disabled,
    ...rest
}) => {
    return isDisplayMode ? (
        <FormDisplay label={label} value={checked ? "ON" : "OFF"} />
    ) : (
        <MDBox display="flex" alignItems="center">
            <FormControlLabel
                control={<Field name={name} label={label} as={Checkbox} checked={checked} {...rest} />}
                label={label}
                disabled={disabled}
            />
        </MDBox>
    );
};

interface RadioProps {
    label?: string;
    name: string;
    options: string[];
    value?: string | boolean;
    isDisplayMode?: boolean;
    onChange?: (e: any) => void;
}

export const FormRadio: React.FC<RadioProps> = ({ label, name, options, value, isDisplayMode, ...rest }) => {
    return isDisplayMode ? (
        <FormDisplay label={label} value={value} />
    ) : (
        <FormControl>
            <RadioGroup defaultValue={false} name="radio-buttons-group" sx={{ display: "inline" }}>
                <FormControlLabel
                    value={false}
                    control={<Field name={name} as={Radio} {...rest} />}
                    label={options[0]}
                    sx={{ display: "inline" }}
                />
                <FormControlLabel
                    value={true}
                    control={<Field name={name} as={Radio} {...rest} />}
                    label={options[1]}
                    sx={{ display: "inline", ml: 5 }}
                />
            </RadioGroup>
        </FormControl>
    );
};

/////////////////////
interface InputRHFProps {
    label?: string;
    name: string;
    success?: boolean;
    value?: string | number;
    isDisplayMode?: boolean;
    helperText?: string;
    handleOnChange?: (e: any) => void;
    editDate?: boolean;
    [key: string]: any;
}

export const FormFieldRHF: React.FC<InputRHFProps> = forwardRef(
    ({ label, name, success, value, isDisplayMode, helperText, control, handleOnChange, editDate, ...rest }, ref) => {
        return isDisplayMode ? (
            <FormDisplay label={label} value={value.toString().length > 0 ? value.toString() : "None"} />
        ) : (
            <MDBox sx={{ position: "relative" }}>
                <Controller
                    // defaultValue={value}
                    render={({ field: { ref, onChange, ...field } }) => {
                        if (editDate) {
                            return (
                                <MDInput
                                    {...rest}
                                    {...field}
                                    sx={{
                                        "& .MuiFormLabel-asterisk": { color: "#FF0000" },
                                        paddingTop: `${isNil(label) ? "4px" : ""}`,
                                    }}
                                    required={isNotNil(success)}
                                    label={label}
                                    success={success}
                                    ref={ref}
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e);
                                        if (isNotNil(handleOnChange)) handleOnChange(e); // if there's a custom onChange
                                    }}
                                    fullWidth
                                />
                            );
                        } else {
                            return (
                                <MDInput
                                    {...rest}
                                    {...field}
                                    sx={{
                                        "& .MuiFormLabel-asterisk": { color: "#FF0000" },
                                        paddingTop: `${isNil(label) ? "4px" : ""}`,
                                    }}
                                    required={isNotNil(success)}
                                    label={label}
                                    success={success}
                                    ref={ref}
                                    // value={editDate && value}
                                    onChange={(e: any) => {
                                        onChange(e);
                                        if (isNotNil(handleOnChange)) handleOnChange(e); // if there's a custom onChange
                                    }}
                                    fullWidth
                                />
                            );
                        }
                    }}
                    name={name}
                    control={control}
                />
                <MDBox mt={0.75} sx={{ position: "absolute" }} display="flex">
                    <MDTypography variant="caption" color="error" fontWeight="regular" sx={{ whiteSpace: "nowrap" }}>
                        {helperText}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
    },
);

// Declaring props types for FormSelect
interface SelectRHFtProps {
    label?: string;
    name: string;
    options: any[];
    type: string;
    defaultValue?: string | number;
    isDisplayMode: boolean;
    helperText?: string;
    handleOnChange?: (e: any) => void;
    [key: string]: any;
}

export const FormSelectRHF: React.FC<SelectRHFtProps> = forwardRef(
    (
        {
            name,
            label,
            options,
            type,
            control,
            defaultValue,
            children,
            isDisplayMode,
            helperText,
            handleOnChange,
            ...rest
        },
        ref,
    ) => {
        const labelId = `${name}-label`;
        const renderOptions = options?.map((item: any, idx: number) => {
            // Option with image
            if (type === "image") {
                const country = item.code === "+1" ? "CA" : "TW";
                return (
                    <MenuItem key={idx} value={item.code} sx={{ display: "flex", minWidth: 0 }}>
                        <MDBox display="flex" alignItems="center">
                            <img src={require(`assets/images/icons/flags/${country}.png`)} alt={country} />
                            <MDBox ml={1}>{item.code}</MDBox>
                        </MDBox>
                    </MenuItem>
                );
            }
            // Should have key value pair for id storage and localization
            else {
                return (
                    <MenuItem key={idx} value={item.value} sx={{ display: "flex", minWidth: 0 }}>
                        {item.option}
                    </MenuItem>
                );
            }
        });

        return isDisplayMode ? (
            <FormDisplay label={label} value={defaultValue} />
        ) : (
            <MDBox sx={{ position: "relative" }}>
                <FormControl {...rest} variant="standard" fullWidth>
                    <InputLabel id={labelId}>{label}</InputLabel>
                    <Controller
                        render={({ field: { ref, onChange, ...field } }) => (
                            <Select
                                {...field}
                                onChange={(e) => {
                                    onChange(e);
                                    if (isNotNil(handleOnChange)) handleOnChange(e); // if there's a custom onChange
                                }}
                                sx={{ paddingTop: "4px", paddingBottom: "5px" }}
                                ref={ref}>
                                {renderOptions}
                            </Select>
                        )}
                        name={name}
                        control={control}
                        defaultValue={defaultValue}
                    />
                </FormControl>
                <MDBox mt={0.75} sx={{ position: "absolute" }} display="flex">
                    <MDTypography variant="caption" color="error" fontWeight="regular">
                        {helperText}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
    },
);

interface DatePickerRHFProps {
    label?: string;
    name: string;
    value: Dayjs;
    isDisplayMode: boolean;
    [key: string]: any;
}

export const FormTimePickerRHF: React.FC<DatePickerRHFProps> = forwardRef(
    ({ label, name, value, isDisplayMode, control, ...rest }, ref) => {
        return isDisplayMode ? (
            <FormDisplay label={label} value={value.format().substring(0, 10)} />
        ) : (
            <Controller
                render={({ field: { ref, ...field } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DemoContainer components={["DatePicker"]}>
                            <DatePicker label={label} value={value} {...field} ref={ref} />
                        </DemoContainer>
                    </LocalizationProvider>
                )}
                name={name}
                control={control}
            />
        );
    },
);
