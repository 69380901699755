/* eslint-disable no-unused-vars */

/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, ReactNode, useContext, useMemo, useReducer } from "react";

// The Material Dashboard 2 PRO React TSUI Dashboard PRO Material main context
const MaterialUI = createContext<any>(null);

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// types
interface StateTypes {
    miniSidenav: boolean;
    layout: "dashboard" | "page";
    transparentNavbar: boolean;
    fixedNavbar: boolean;
}

interface ActionTypes {
    type: "MINI_SIDENAV" | "LAYOUT" | "TRANSPARENT_NAVBAR" | "FIXED_NAVBAR";

    value: any;
}

// Material Dashboard 2 PRO React reducer
const reducer = (state: StateTypes, action: ActionTypes) => {
    switch (action.type) {
        case "MINI_SIDENAV": {
            return { ...state, miniSidenav: action.value };
        }
        case "LAYOUT": {
            return { ...state, layout: action.value };
        }
        case "TRANSPARENT_NAVBAR": {
            return { ...state, transparentNavbar: action.value };
        }
        case "FIXED_NAVBAR": {
            return { ...state, fixedNavbar: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

// Material Dashboard 2 PRO React context provider
const MaterialUIControllerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const initialState: StateTypes = {
        miniSidenav: false,
        transparentNavbar: true,
        fixedNavbar: true,
        layout: "page",
    };

    const [controller, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

    return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
};

// Material Dashboard 2 PRO React custom hook for using context
const useMaterialUIController = () => {
    const context = useContext(MaterialUI);

    if (!context) {
        throw new Error("useMaterialUIController should be used inside the MaterialUIControllerProvider.");
    }

    return context;
};

// Context module functions
const setMiniSidenav = (dispatch: (arg: { type: "MINI_SIDENAV"; value: boolean }) => void, value: boolean) =>
    dispatch({ type: "MINI_SIDENAV", value });
const setTransparentNavbar = (
    dispatch: (arg: { type: "TRANSPARENT_NAVBAR"; value: boolean }) => void,
    value: boolean,
) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch: (arg: { type: "FIXED_NAVBAR"; value: boolean }) => void, value: boolean) =>
    dispatch({ type: "FIXED_NAVBAR", value });
const setLayout = (
    dispatch: (arg: { type: "LAYOUT"; value: "dashboard" | "page" }) => void,
    value: "dashboard" | "page",
) => dispatch({ type: "LAYOUT", value });

export {
    MaterialUIControllerProvider,
    useMaterialUIController,
    setMiniSidenav,
    setTransparentNavbar,
    setFixedNavbar,
    setLayout,
};
