import * as Yup from "yup";
import checkout from "layouts/settings/schemas/form";

const {
    formField: {
        unit: { unitNumber },
    },
} = checkout;

const validations = {
    unit: [
        Yup.object().shape({
            unitNumber: Yup.string().required(unitNumber.errorMsg),
            address: Yup.string(),
        }),
    ],
};

export default validations;
