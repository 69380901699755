/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 REL Technology Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";
import FormTitle from "components/MDFormField/title";
import { isNotNil } from "helpers/utils";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

interface Props {
    children: ReactNode;
    header?: string;
    name?: string;
    fullWidth?: boolean;
}

export const FormLayout: React.FC<Props> = ({ children, header, name, fullWidth }) => {
    return (
        <MDBox
            bgColor="white"
            p={{ xs: 2, md: 5 }}
            sx={{
                height: "100%",
                borderRadius: "8px",
                maxHeight: "90vh",
                overflowY: "auto",
                maxWidth: fullWidth ? "100%" : "1200px",
            }}>
            <FormTitle content={`${isNotNil(header) ? header : ""} ${isNotNil(name) ? name : ""}`} />
            {children}
        </MDBox>
    );
};

export default FormLayout;
