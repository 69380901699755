import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/userContext";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDCircularProgress from "components/MDCircularProgress";
import MDNotification from "components/MDNotification";
import AddButton from "components/MDButton/addButton";

// Components
import BasicLayout from "examples/LayoutContainers/BasicLayout";
import BusinessCard from "layouts/overview/business-page/components/BusinessCard";
import NewBusiness from "layouts/settings/business-page/newBusiness";

// Business service
import businessService from "services/business.service";
import AlarmService from "services/alarm.service";

// Types
import { Business } from "types/setting-schema";
import { FormOperation, FormType } from "types/enums";
import { isRelAdmin } from "types/user-schema";

// Helpers
import { isNotNil, isNil } from "helpers/utils";

// i18n
import { useTranslation } from "react-i18next";
import { AlertRecord } from "types/alert-schema";
import MDTab from "components/MDTab";

import Report from "layouts/report/Report";
export interface BusinessWithAlerts extends Business {
    todayAlerts?: AlertRecord[];
    totalAlertsYesterday?: number;
    onlineResidents: number;
}

export const BusinessInfoPage: React.FC = () => {
    const { currentUser } = useAuth();
    const [businessData, setBusinessData] = useState<BusinessWithAlerts[]>(null);
    const [activeSetting, setActiveSetting] = useState("live");

    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        fetchBusinesses();
    }, []);

    const fetchBusinesses = async () => {
        const res = await businessService.getAllBusinessesWithOnlineStatus();
        if (res.success) {
            const data = res.data;
            setBusinessData(data); // Set the business data immediately

            data.forEach(async (business: BusinessWithAlerts) => {
                const alerts = await fetchAlerts(business._id);
                setBusinessData((prevData) =>
                    prevData.map((item) =>
                        item._id === business._id
                            ? {
                                  ...item,
                                  todayAlerts: alerts?.todayAlerts,
                                  totalAlertsYesterday: alerts?.totalAlertsYesterday,
                              }
                            : item,
                    ),
                );
            });
        }
    };

    const fetchAlerts = async (id: string) => {
        const res = await AlarmService.getTodayBusinessAlarm(
            "businessId",
            id,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
        if (res.success) return res.data;
    };

    const renderBusinessCard = () => {
        return businessData.map((item: BusinessWithAlerts) => <BusinessCard key={item._id} {...item} />);
    };

    const renderReport = () => {

        return (
            <MDBox
                sx={{
                    background: "#FFFFFF",
                    minHeight: "500px",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                    position: "relative",
                    width: "100%",
                    margin: "24px",
                    // padding: "24px",
                }}>
                <Report businessId="64475a199d4b4a2c835a5d23" centerId="All"/>
            </MDBox>
        );
    };

    if (!isRelAdmin) {
        return (
            <BasicLayout>
                <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                    <MDNotification content={translate("common:permissionDeniedMessage")} />
                </MDBox>
            </BasicLayout>
        );
    }

    return (
        <BasicLayout>
            {isNil(businessData) ? (
                <MDCircularProgress color="primary" />
            ) : (
                <>
                    <MDBox mb={3}>
                        <MDTab
                            list={[
                                { key: translate("info.realTimeInfo.live"), value: "live" },
                                { key: translate("info.realTimeInfo.report"), value: "report",disabled:true },
                            ]}
                            active={activeSetting}
                            scrollable={false}
                            setActive={setActiveSetting}
                        />
                    </MDBox>
                    <Grid container spacing={3} maxWidth={1920} display={{ xxl: "flex" }}>
                        {activeSetting === "live" && renderBusinessCard()}
                        {activeSetting === "report" && renderReport()}
                    </Grid>
                </>
            )}
        </BasicLayout>
    );
};

export default BusinessInfoPage;
