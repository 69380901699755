import { Room } from "types/websocket-schema";

const MAX_TIMER_INTERVAL = 60 * 1000;
const MIN_TIMER_INTERVAL = 1;
const DAY_MS = 24 * 60 * 60 * 1000;
const month_names_short = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const month_names_long = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const IMAGE_SIZE_REGEX = /\/\d+x\d+\//i;

/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 *
 * isNil(null)
 * // => true
 *
 * isNil(void 0)
 * // => true
 *
 * isNil(NaN)
 * // => false
 */
export const isNil = <TValue>(value: TValue | null | undefined): value is null | undefined => {
    return value == null;
};

export const isNotNil = <TValue>(value: TValue | null | undefined): value is TValue => {
    return value != null;
};

export const isEmptyArray = (array?: Array<any> | null | undefined) => {
    if (isNil(array)) {
        return true;
    }

    return array.length === 0;
};

export const getPercentage = (number?: number, total?: number): number => {
    if (number == null || number < 0 || total == null || total <= 0) {
        return 0;
    }
    return Math.round((number / total) * 100);
};

export const stringEqualsIgnoreCase = (strA?: string | null, strB?: string | null) => {
    if (isNil(strA) && isNil(strB)) {
        return true;
    }

    if (isNil(strA) || isNil(strB)) {
        return false;
    }

    return strA.toLocaleLowerCase().localeCompare(strB.toLocaleLowerCase(), undefined, { sensitivity: "accent" }) === 0;
};

export const isStringBlank = (str?: string | null): boolean => {
    return isNil(str) || str.trim().length === 0;
};

export const isStringNotBlank = (str?: string | null): boolean => {
    return !isStringBlank(str);
};

export const isStringEmpty = (str?: string | null): boolean => {
    return isNil(str) || 0 === str.length;
};

/**
 * Test a url is relative path
 * @param url url
 */
export const isRelativePath = (url: string): boolean => {
    const isAbsolutePathReg = new RegExp("^(//|[a-z]+:)", "i");
    return isAbsolutePathReg.test(url) === false;
};

/**
 * Return the timer interval in a reasonable range. If the timer interval is more than one minute, Android will pop up an warning. If the timer is
 * less than 1ms, the app does not behave properly
 * https://github.com/aws-amplify/amplify-js/issues/5075
 * @param interval
 */
export const fixTimerInterval = (interval: number) => {
    if (interval > MAX_TIMER_INTERVAL) {
        return MAX_TIMER_INTERVAL;
    }

    if (interval < MIN_TIMER_INTERVAL) {
        return MIN_TIMER_INTERVAL;
    }

    return interval;
};

export const toNumber = (number?: string): number | undefined => {
    if (isNil(number) || number.length === 0 || isNaN(Number(number))) {
        return;
    }
    return Math.abs(Number(number));
};

export const toBoolean = (boolean?: string): boolean | undefined => {
    if (isNil(boolean)) return;
    if (boolean?.toLowerCase() === "true") return true;
    if (boolean?.toLowerCase() === "false") return false;
    return;
};
export const convertDataArrayTo = <Item>(items: Item[], size: number): Item[][] => {
    // return Array(Math.ceil(items.length / size)).fill(null).map(_ => items.splice(0, size));
    let arrayOfArrays: Item[][] = [];
    for (var i = 0; i < items.length; i += size) {
        arrayOfArrays.push(items.slice(i, i + size));
    }
    return arrayOfArrays;
};

export const bbyProductImageSize100Url = (url?: string) => {
    if (isNil(url)) return url;
    return url.replace(IMAGE_SIZE_REGEX, "/100x100/");
};

export const bbyProductImageSize500Url = (url?: string) => {
    if (isNil(url)) return url;
    return url.replace(IMAGE_SIZE_REGEX, "/500x500/");
};

export const bbyProductImageSize1500Url = (url?: string) => {
    if (isNil(url)) return url;
    return url.replace(IMAGE_SIZE_REGEX, "/1500x1500/");
};

export const stringToUtcDate = (dateString: string | undefined | null) => {
    if (isNotNil(dateString)) {
        let utcDate = new Date(dateString);
        if (isNil(utcDate) || isNaN(utcDate.getTime())) {
            return undefined;
        }
        return utcDate;
    }

    return undefined;
};

export const dateDiffInDays = (dateA: Date, dateB: Date) => {
    let diff = dateA.getTime() - dateB.getTime();
    return Math.abs(diff / DAY_MS);
};

export const monthToShortString = (month: number, isLong: boolean = false) => {
    return isLong ? month_names_long[month] : month_names_short[month];
};

// Ref: https://bitbucketdev.ca.bestbuy.com/projects/APEX/repos/ecomm-webapp/browse/src/client/components/AgeGate/AgeGate.tsx#29
const UTC_START_YEAR: number = 1970; // https://en.wikipedia.org/wiki/Unix_time
export const calculateAge = (year: number, month: number, day: number): number => {
    const birthDate = new Date(year, month - 1, day);
    const timeDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(timeDiff);
    return Math.abs(ageDate.getUTCFullYear() - UTC_START_YEAR);
};

export const calculateAgeFromString = (year?: string, month?: string, day?: string): number => {
    const yearNum = toNumber(year);
    const monthNum = toNumber(month);
    const dayNum = toNumber(day);
    if (isNil(yearNum) || isNaN(yearNum) || isNil(monthNum) || isNaN(monthNum) || isNil(dayNum) || isNaN(dayNum)) {
        return -1;
    }
    return calculateAge(yearNum, monthNum, dayNum);
};

//Date input validation

export const isNumeric = (val: string): boolean => {
    return Number.isInteger(parseInt(val, 10));
};

export const inRange = (min: number, max: number, val: any): boolean => {
    return isNumeric(val) && val >= min && val <= max;
};

export const getProvinceCodeSet = (): Set<string> => {
    const regionNameSet = new Set<string>();
    const regionNames = ["NL", "PE", "NS", "NB", "QC", "ON", "MB", "SK", "AB", "BC", "YT", "NT", "NU"];
    for (let region of regionNames) {
        regionNameSet.add(region);
    }
    return regionNameSet;
};

export const isRegionNameValid = (regionName: string): boolean => {
    return getProvinceCodeSet().has(regionName.toUpperCase());
};

export const removeLineBreaks = (text: string): string => {
    const textRemoved = text.replace(/[\r\n]+/g, " ");
    return textRemoved;
};

export const convertHexStringToHexColorCodeIfNeeded = (hex?: string) => {
    if (isNil(hex)) return hex;
    const colorString = new RegExp(/^([0-9a-fA-F]{3}){1,2}$/);
    if (colorString.test(hex)) {
        return "#" + hex;
    }
    return hex;
};

export const firebaseEventName = (name: string): string => {
    var modifiedName = name.replace(/[\s-|\r\n,:.*?!@#^`~%=></]+/g, "_");
    if (modifiedName.length > 40) {
        modifiedName = modifiedName.slice(0, 40);
    }

    let regexCheck = new RegExp(/^([A-z0-9_])*$/);
    if (regexCheck.test(modifiedName)) {
        return modifiedName;
    } else {
        return "undefined";
    }
};

export const exportDataDate = (timeRange: string) => {
    const currentDate = new Date();
    const exportedArray = [];

    switch (timeRange) {
        case "week":
            for (let i = 6; i >= 0; i--) {
                const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - i);
                const shortName = date.toLocaleDateString("en-US", { weekday: "short" });
                exportedArray.push(shortName);
            }
            break;
        case "twoWeek":
            for (let i = 13; i >= 0; i--) {
                const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - i);
                const shortName = date.toLocaleDateString("en-US", { weekday: "short" });
                exportedArray.push(shortName);
            }
            break;
        case "month":
            for (let i = 0; i < 31; i++) {
                const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - i);
                const dayNumber = date.getDate();
                exportedArray.unshift(dayNumber);
            }
            break;
        case "year":
            for (let i = 11; i >= 0; i--) {
                const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
                const shortName = date.toLocaleDateString("en-US", { month: "short" });
                exportedArray.push(shortName);
            }
            break;
        default:
            break;
    }
    // exportedArray.reverse();
    return exportedArray;
};

export const roundNumber = (num: number, decimalPlaces: number): number => {
    const factor = 10 ** decimalPlaces;
    return Math.round(num * factor) / factor;
};

export const splitByComma = (inputString: string): string[] => {
    const regex = /(?<!\s),\s/g;
    const splitValues = inputString.split(regex);
    // Remove leading and trailing whitespaces from each value
    const trimmedValues: string[] = splitValues.map((value) => value.trim());

    return trimmedValues;
};

export const convertTimestampToReadableDate = (timestamp: Date, hour?: boolean, br?: boolean): string => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    if (hour) {
        if (br) {
            return `${year}-${month}-${day}\n${date.getHours()}:${date.getMinutes()}`;
        } else {
            return `${year}-${month}-${day} ${date.getHours()}:${date.getMinutes()}`;
        }
    }
    return `${year}-${month}-${day}`;
};


export const convertToObject = (data: { [key: string]: string }): { [key: string]: Room } => {
    const keys = Object.keys(data);
    if (!data || keys.length === 0) {
        return {};
    }
    const result: { [key: string]: Room } = {};
    keys.forEach((element) => {
        try {
            result[element] = data[element] ? JSON.parse(data[element]) : null;
        } catch (e) {
            console.error(`Error parsing data for key ${element}:`, e);
            result[element] = null;
        }
    });
    return result;
};

const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
};

export const updateData = (currentData: { [key: string]: Room }, newData: { [key: string]: Room }): { [key: string]: Room } => {
    const updatedData: { [key: string]: Room } = { ...currentData };

    Object.keys(newData).forEach(key => {
        // Check if currentData exists and if currentData[key] exists before comparing
        if (!currentData || !currentData[key] || !deepEqual(currentData[key], newData[key])) {
            updatedData[key] = newData[key];
        }
    });

    return updatedData;
};

export const isFallAndLong = (bed: boolean, livingRoom: boolean, kitchen: boolean, bathRoom: boolean) => {
    if (bed || livingRoom || kitchen || bathRoom) {
        return true;
    }
    return false;
};
