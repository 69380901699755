import { Response, default as http } from "http-common";
import { User } from "types/user-schema";

class ReportService {
    async getSummaryReport(date: any, timezone: any, page: any): Promise<Response<any[]>> {
        const response = await http.post<Response<any[]>>("/api/v1/report/summaryReport", {
            page: page,
            date: date,
            timezone: timezone,
        });
        return response.data;
    }
}

export default new ReportService();
