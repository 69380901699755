import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDCircularProgress from "components/MDCircularProgress";
import MDNotification from "components/MDNotification";
import MDNoData from "components/MDNoData";
import More from "components/MDIcon/more";

// Setting layout components
import BasicLayout from "examples/LayoutContainers/BasicLayout";
import ContactDetails from "layouts/settings/components/DisplayCard/contactDetails";
import CollectionNumber from "layouts/settings/components/DisplayCard/collectionNumber";
import UnitAddress from "layouts/settings/components/DisplayCard/unitAddress";
import ViewBusiness from "layouts/settings/business-page/viewBusiness";
import ViewCenter from "layouts/settings/business-page/viewCenter";
import ViewUnit from "layouts/settings/business-page/viewUnit";

// Service
import businessService from "services/business.service";

// Utils
import { isNil, isNotNil } from "helpers/utils";

// Types
import { Business, Center, Unit, RoomDevice, Device } from "types/setting-schema";
import { FormOperation, FormType, RoomType, getIconForRoomType } from "types/enums";

// i18n
import { useTranslation } from "react-i18next";

interface FormProps {
    type: FormType;
    op: FormOperation;
    centerId?: string;
    unitId?: string;
}

interface RoomProps {
    name: string;
    icon: string;
    count: number;
}

export const SingleBusinessDisplay: React.FC = () => {
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [mode, setMode] = useState<FormProps | null>(null);
    const [formAlert, setFormAlert] = useState<boolean>(false);
    const state = useLocation().state as Business;
    const { _id: businessId } = state;
    const [business, setBusiness] = useState<Business | undefined>(undefined);
    const navigate = useNavigate();
    const { t: translate } = useTranslation(["common"]);

    useEffect(() => {
        const fetchBusiness = async () => {
            const res = await businessService.getSingleBusiness(businessId);
            if (res.success) {
                setBusiness(res.data);
            }
        };

        fetchBusiness();
    }, [businessId]);

    useEffect(() => {
        if (formAlert) {
            setTimeout(() => {
                setFormAlert(false);
            }, 3000);
        }
    }, [formAlert]);

    const childCallback = async (updated: boolean, isDelete?: boolean) => {
        if (updated) {
            if (isDelete) setMode({ ...mode, op: FormOperation.DELETE });
            const res = await businessService.getSingleBusiness(businessId);
            if (res.success) {
                if (res.data) setBusiness(res.data);
            } else {
                navigate("/setting/business", { state: { op: FormOperation.DELETE } });
            }
            setToggleModal(false);
            setFormAlert(true);
        }
    };

    const renderForm = () => {
        const ct = business.centers.find((item) => item._id === mode.centerId);

        if (mode.op === FormOperation.UPDATE) {
            if (mode.type === FormType.BUSINESS) {
                return <ViewBusiness setToggleModal={setToggleModal} business={business} callback={childCallback} />;
            } else if (mode.type === FormType.CENTER) {
                return <ViewCenter setToggleModal={setToggleModal} center={ct} callback={childCallback} />;
            } else if (mode.type === FormType.UNIT) {
                const ut = ct.units.find((item) => item._id === mode.unitId);
                let unit = { ...ut };
                unit.centerName = ct.name;
                return (
                    <ViewUnit
                        setToggleModal={setToggleModal}
                        businessId={businessId}
                        unit={unit}
                        callback={childCallback}
                    />
                );
            }
        }
    };

    const handleToggleModal = (type: FormType, op: FormOperation, centerId?: string, unitId?: string) => {
        setToggleModal(true);
        setMode({ type: type, op: op, centerId: centerId, unitId: unitId });
    };

    const renderPage = () => {
        return (
            <>
                {renderBusiness()}
                <MDBox>{renderCenters()}</MDBox>
            </>
        );
    };

    const renderBusiness = () => {
        return (
            <MDBox>
                <MDTypography variant="h2">Business</MDTypography>
                <Card sx={{ mb: 6 }}>
                    <Grid
                        container
                        p={3}
                        columnSpacing={2}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleToggleModal(FormType.BUSINESS, FormOperation.UPDATE)}>
                        <Grid item xs={3}>
                            <MDTypography variant="h2">{business.name}</MDTypography>
                        </Grid>
                        <ContactDetails contact={business.contact} phone={business.phone} address={business.address} />
                        <Grid item xs={0.5}>
                            <Divider orientation="vertical" sx={{ backgroundColor: "#000" }} />
                        </Grid>
                        <CollectionNumber
                            collections={[
                                { name: translate("general.type.center"), number: business.centers.length },
                                { name: translate("general.type.unit"), number: business.numUnits },
                                { name: translate("general.type.resident"), number: business.numResidents },
                            ]}
                        />
                        <More />
                    </Grid>
                </Card>
            </MDBox>
        );
    };

    const renderCenters = () => {
        return (
            isNotNil(business.centers) &&
            business.centers.map((item: Center) => {
                return (
                    <MDBox key={item._id} mb={6}>
                        <MDTypography variant="h2">Center</MDTypography>
                        <Card sx={{ mb: 2 }}>
                            <Grid
                                container
                                p={3}
                                columnSpacing={2}
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleToggleModal(FormType.CENTER, FormOperation.UPDATE, item._id)}>
                                <Grid item xs={3}>
                                    <MDTypography variant="h2">{item.name}</MDTypography>
                                    <MDBadge
                                        variant="gradient"
                                        size="md"
                                        badgeContent={
                                            item.isCentralized
                                                ? translate("setting.center.centralized")
                                                : translate("setting.center.remote")
                                        }
                                        color="primary"
                                        container
                                        circular
                                    />
                                </Grid>
                                <ContactDetails contact={item.contact} phone={item.phone} address={item.address} />
                                <Grid item xs={0.5}></Grid>
                                <CollectionNumber
                                    collections={[
                                        { name: translate("general.type.unit"), number: item.numUnits },
                                        { name: translate("general.type.resident"), number: item.numResidents },
                                        { name: translate("general.type.device"), number: item.numDevices },
                                    ]}
                                />
                                <More />
                            </Grid>
                        </Card>
                        {item.units?.length > 0 ? (
                            <>
                                <MDTypography variant="h2">Unit</MDTypography>
                                {renderUnits(item.units, item.isCentralized)}
                            </>
                        ) : (
                            <MDNoData content={translate("general.notification.noUnit")} margin={2} />
                        )}
                    </MDBox>
                );
            })
        );
    };

    const calculateDevices = (roomDevices: RoomDevice[]) => {
        if (roomDevices.length === 0) {
            return {
                roomCount: null,
                allInstalled: false,
            };
        }

        // Declare a dictionary to store room count by type
        const roomCount: { [key in string]?: number } = {};

        let allInstalled = true;
        roomDevices.forEach((element: RoomDevice) => {
            let type = element.room as RoomType;
            if (isNil(roomCount[type])) {
                roomCount[type] = 1;
            } else {
                roomCount[type] += 1;
            }

            element.devices.forEach((device: Device) => {
                // if any of the device is not installed, display not install
                if (device.status === "not installed") allInstalled = false;
            });
        });

        return { roomCount: roomCount, allInstalled: allInstalled };
    };

    const renderUnits = (units: Unit[], isCentralized: boolean) => {
        return units.map((item: any) => {
            const { roomCount, allInstalled } = calculateDevices(item.roomDevices);
            return (
                <Card sx={{ mb: 1 }} key={item._id}>
                    <Grid
                        container
                        p={3}
                        columnSpacing={2}
                        alignItems="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleToggleModal(FormType.UNIT, FormOperation.UPDATE, item.center, item._id)}>
                        <Grid item xs={1}>
                            <MDTypography>{item.unitNumber}</MDTypography>
                        </Grid>
                        <UnitAddress address={!isCentralized ? item.address : null} floor={item.floor} />
                        <Grid item xs={3.5} display="flex" alignItems="center">
                            {isNotNil(roomCount) && (
                                <>
                                    <Icon fontSize="medium">wifi</Icon>
                                    {Object.keys(roomCount).map((key, index) => {
                                        return (
                                            <MDBox key={index} display="flex">
                                                {roomCount[key] > 0 && (
                                                    <>
                                                        <MDBox mx={1} display="flex" alignItems="center">
                                                            <Icon fontSize="medium">
                                                                {getIconForRoomType(key as RoomType)}
                                                            </Icon>
                                                            <MDTypography ml={1}>{roomCount[key]}</MDTypography>
                                                        </MDBox>
                                                        {index !== Object.keys(roomCount).length - 1 && "/"}
                                                    </>
                                                )}
                                            </MDBox>
                                        );
                                    })}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                            <MDBadge
                                variant="gradient"
                                size="lg"
                                badgeContent={
                                    allInstalled
                                        ? translate("setting.unit.installStatus.installed")
                                        : translate("setting.unit.installStatus.notInstalled")
                                }
                                color={allInstalled ? "primary" : "light"}
                                container
                                circular
                            />
                        </Grid>
                        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
                            <Icon fontSize="medium">person</Icon>
                            <MDTypography ml={0.5}>{item.numberOfResidents}</MDTypography>
                        </Grid>
                        <More />
                    </Grid>
                </Card>
            );
        });
    };

    return (
        <BasicLayout>
            {toggleModal && renderForm()}
            {formAlert && (
                <MDNotification
                    content={translate("general.notification.message", {
                        type: `${translate(`general.type.${mode.type}`)}`,
                        operation: `${translate(`general.operation.past.${mode.op}`)}`,
                    })}
                />
            )}
            {isNil(business) ? <MDCircularProgress color="primary" /> : renderPage()}
        </BasicLayout>
    );
};

export default SingleBusinessDisplay;
